
<div class="UpsellPage-ControlPanel">
  <pf-mat-daterange
    [defaultDateRange]="defaultDateRange"
    (onSelectedRange)="selectedDate($event)"
  ></pf-mat-daterange>

  <div class="mr-0 ml-auto">
    <button class="one-button xs purple outline mr-2 _mobile-hidden" (click)="navigateToTutorials()">
      <one-icon name="videocam-outline"></one-icon> Tutorial
    </button>
    <button (click)="createUpsell()" 
      *ngIf="!limitBlocker?.featureBlocked" 
      class="one-button xs filled blue"
    >Create Upsell</button>
  </div>

  <div class="_mobile-visible">
    <div [matMenuTriggerFor]="menu" class="pf-options-button ml-2">
      <one-icon name="ellipsis-horizontal"></one-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="navigateToTutorials()">Tutorial</button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="UpsellPage-Stats">
  <pf-upsell-cards [stats]="stats"></pf-upsell-cards>
</div>

<div class="w-100 mb-12px" *ngIf="!postPurchaseAppInUse">
  <pf-message-box
    messageType="error"
    customClass="mb-0"
    buttonText="Update Settings"
    (buttonClick)="updateSettings()"
    [buttonClass]="'one-button xs filled red'"
    [messageHtml]="messageHtml"
    [mobileLayout]="true"
  ></pf-message-box>
</div>

<div class="SectionTitle _has-controls">
  <div>
    {{ tableTitle }}
    <span class="SectionTitle-Badges">
      <span class="pf-badge success">One-Click Checkout</span>
    </span>
  </div>
  <pf-filter-select
    class="SectionTitle-Controls"
    title="Status"
    [options]="filterOptions"
    [(ngModel)]="filterModel"
    (change)="filterChanged()"
  ></pf-filter-select>
</div>

<div class="Card pb-2 _scroll-x" [class.pb-4]="limitBlocker?.featureBlocked" style="position: relative;">
  <one-limit-blocker #limitBlocker [id]="RouteHeaderUrl.upsell">
    <table mat-table [dataSource]="dataSource" class="pt-3 UpsellPage-Table pf-table" (cdkDropListDropped)="drop($event)" cdkDropList [cdkDropListData]="dataSource">
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>Priority</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Priority'" *matCellDef="let row; let i = index">
          <div class="root-cell">
            <div cdkDragHandle class="UpsellPage-Table-Handle">
              {{ isMobile ? '' : i + 1 }}
              <div class="UpsellPage-Mobile-Buttons" *ngIf="isMobile">
                <div class="UpsellPage-Mobile-MoveBtn" *ngIf="i !== 0" (click)="mobileMoveButtonClick(row, i, 'up')">
                  <one-icon name="arrow-up-circle"></one-icon>
                </div>
                {{ i + 1 }}
                <div class="UpsellPage-Mobile-MoveBtn" *ngIf="i !== ((dataSource.data$ | async)?.length - 1)" (click)="mobileMoveButtonClick(row, i, 'down')">
                  <one-icon name="arrow-down-circle"></one-icon>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Offer Name</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Offer Name'" *matCellDef="let row;">
          <div class="root-cell _name" [title]="row.name">
            <div>{{row.name}}</div>
          </div>
          <ng-container *ngFor="let variant of row.other_variants; let i = index">
            <div class="variant-cell _name" [class._first]="i === 0" [title]="variant.name">
              <div>{{variant.name}}</div>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef>Discount</th>
        <td mat-cell [attr.data-title]="'Discount'" *matCellDef="let row;">
          <div class="root-cell _discount">{{row.root_variant?.discount_text || 'None'}}</div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _discount">{{variant?.discount_text || 'None'}}</div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="shipping">
        <th mat-header-cell *matHeaderCellDef>Shipping</th>
        <td mat-cell class="conveythis-no-translate" *matCellDef="let row;">
          <div class="root-cell _shipping" [title]="row.name">
            <span *ngIf="row.root_variant.shipping?.type === upsellShippingType.Free">Free</span>
            <span *ngIf="row.root_variant.shipping?.type === upsellShippingType.Fixed">
              {{(row.root_variant.shipping?.amount || 0) | currency:userCurrency:'symbol':'1.2-2'}}
              <small class="text-muted" *ngIf="row.root_variant.shipping?.per_unit">/item</small>
            </span>
          </div>
          <ng-container *ngFor="let variant of row.other_variants; let i = index">
            <div class="variant-cell _shipping" [class._first]="i === 0">
              <span *ngIf="variant.shipping?.type === upsellShippingType.Free">Free</span>
              <span *ngIf="variant.shipping?.type === upsellShippingType.Fixed">
                {{(variant.shipping?.amount || 0) | currency:userCurrency:'symbol':'1.2-2'}}
                <small class="text-muted" *ngIf="variant.shipping?.per_unit">/item</small>
              </span>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef>Weight</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Weight'" *matCellDef="let row;">
          <ng-container *ngIf="row.other_variants?.length !== 0">
            <div class="root-cell _weight">
              <ng-select
                [clearable]="false"
                [searchable]="false"
                [items]="weightOptions"
                (change)="onWeightChange(row, row.root_variant)"
                [(ngModel)]="row.root_variant.weight">
                <ng-template ng-header-tmp>
                  <div class="weight-select-header">Display %</div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div class="weight-select-option">
                    <span class="weight-select-value">{{item | number}}</span>
                    <span class="weight-select-percentage">{{(item / (row.total_weight - row.root_variant.weight + item)) | percent : '1.0-1'}}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </ng-container>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _weight">
              <ng-select
                [clearable]="false"
                [items]="weightOptions"
                (change)="onWeightChange(row, variant)"
                [(ngModel)]="variant.weight">
                <ng-template ng-header-tmp>
                  <div class="weight-select-header">Display %</div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div class="weight-select-option">
                    <span class="weight-select-value">{{item | number}}</span>
                    <span class="weight-select-percentage">{{(item / (row.total_weight - variant.weight + item)) | percent : '1.0-1'}}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="display">
        <th mat-header-cell *matHeaderCellDef>Display</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Display'" *matCellDef="let row;">
          <div class="root-cell _percent">
            <ng-container *ngIf="row.other_variants?.length !== 0; else hundredPercent">
              {{(row.root_variant?.weight / row.total_weight) | percent : '1.0-1'}}
            </ng-container>
            <ng-template #hundredPercent>100%</ng-template>
          </div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _percent">{{(variant?.weight / row.total_weight) | percent : '1.0-1'}}</div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="views">
        <th mat-header-cell *matHeaderCellDef>Views</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Views'" *matCellDef="let row;">
          <div class="root-cell _views">{{row.root_variant?.views || 0}}</div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _views">{{variant?.views || 0}}</div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="conversions">
        <th mat-header-cell *matHeaderCellDef>Conversions</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Conversions'" *matCellDef="let row;">
          <div class="root-cell _conversions">{{row.root_variant?.conversions || 0}}</div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _conversions">{{variant?.conversions || 0}}</div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="revenue">
        <th mat-header-cell *matHeaderCellDef>Revenue</th>
        <td mat-cell class="conveythis-no-translate" [attr.data-title]="'Revenue'" *matCellDef="let row;">
          <div class="root-cell _revenue">{{(row.root_variant?.revenue || 0) | currency:userCurrency:'symbol':'1.2-2'}}</div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _revenue">{{(variant?.revenue || 0) | currency:userCurrency:'symbol':'1.2-2'}}</div>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell [attr.data-title]="'Status'" *matCellDef="let row;">
          <div class="root-cell">
            <span class="pf-badge" [ngClass]="badgeColorClass[row.status]">
              {{(row.status === 'on' ? 'Live' : row.status) | pfxCapitalize}}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row;" class="text-right">
          <div class="root-cell _actions">
            <button *ngIf="row.status === upsellStatus.Off"
              class="one-button outline green sm text-nowrap"
              (click)="onPublishUpsell(row)"
              matTooltipClass="Tooltip-Box"
              matTooltipPosition="above"
              matTooltip="Publish">
              <one-icon name="play"></one-icon>
            </button>
            <button *ngIf="row.status === upsellStatus.Live"
              class="one-button outline red sm text-nowrap"
              (click)="onStatusToggle(row, upsellStatus.Off)"
              matTooltipClass="Tooltip-Box"
              matTooltipPosition="above"
              matTooltip="Pause">
              <one-icon name="pause"></one-icon>
            </button>
            <button class="one-button outline blue sm text-nowrap ml-2"
                    (click)="onEditUpsellClick(row)"
                    matTooltipClass="Tooltip-Box"
                    matTooltipPosition="above"
                    matTooltip="Edit">
              <one-icon name="pencil"></one-icon>
            </button>
            <button class="ml-1 _local-options-btn"
                    mat-icon-button
                    matTooltipClass="Tooltip-Box"
                    matTooltipPosition="above"
                    [matTooltip]="isMobile ? '' : 'More Actions'"
                    [matMenuTriggerFor]="menu"
                    [disableRipple]="true">
              <div class="pf-options-button pointer-none">
                <one-icon name="ellipsis-horizontal"></one-icon>
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item
                (click)="onPreviewClick(row)">
                <one-icon name="eye-outline"></one-icon><span> View on Site</span>
              </button> -->
              <button mat-menu-item
                (click)="onRenameClick(row)">
                <one-icon name="pencil"></one-icon> Rename
              </button>
              <button mat-menu-item
                (click)="onDuplicateClick(row)">
                <one-icon name="duplicate-outline"></one-icon> Duplicate
              </button>
              <button mat-menu-item
                (click)="onCreateVariatonClick(row)">
                <one-icon src="/app_assets/icons/ab-test-icon.svg"></one-icon> Create Variation
              </button>
              <button mat-menu-item *ngIf="row.status !== upsellStatus.Archived"
                (click)="onArchiveClick(row, upsellStatus.Archived)">
                <one-icon name="archive-outline"></one-icon><span> Archive</span>
              </button>
              <button mat-menu-item *ngIf="row.status === upsellStatus.Archived"
                (click)="onArchiveClick(row, upsellStatus.Off)">
                <one-icon name="file-tray-outline"></one-icon><span> Restore</span>
              </button>
            </mat-menu>
          </div>
          <ng-container *ngFor="let variant of row.other_variants">
            <div class="variant-cell _actions">
              <button class="ml-1 _local-options-btn"
                      mat-icon-button
                      matTooltipClass="Tooltip-Box"
                      matTooltipPosition="above"
                      [matTooltip]="isMobile ? '' : 'More Actions'"
                      [matMenuTriggerFor]="menu"
                      [disableRipple]="true">
                <div class="pf-options-button pointer-none">
                  <one-icon name="ellipsis-horizontal"></one-icon>
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="variant.weight === 0"
                  (click)="onToggleVariantWeight(row, variant, 10)">
                  <one-icon name="play"></one-icon><span> Publish</span>
                </button>
                <button mat-menu-item *ngIf="variant.weight > 0"
                  (click)="onToggleVariantWeight(row, variant, 0)">
                  <one-icon name="pause"></one-icon><span> Pause</span>
                </button>
                <button mat-menu-item
                  (click)="onEditVariantClick(variant)">
                  <one-icon name="cog"></one-icon> Edit
                </button>
                <button mat-menu-item
                  (click)="onRenameClick(variant)">
                  <one-icon name="pencil"></one-icon> Rename
                </button>
                <button mat-menu-item
                  (click)="onDuplicatVarianteClick(row, variant)">
                  <one-icon name="duplicate-outline"></one-icon> Duplicate
                </button>
              </mat-menu>
            </div>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row" [cdkDragDisabled]="isMobile"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <pf-no-table-data
            [text]="emptyTableText"
          ></pf-no-table-data>
        </td>
      </tr>
    </table>
  </one-limit-blocker>
  <pf-loading-overlay
    class="loading-overlay"
    [filter]="{only: ['MobileMoveButtonClick']}"
    [isFixed]="false"
    *ngIf="!isMobile"
  ></pf-loading-overlay>
  <pf-loading-overlay class="loading-overlay" [isFixed]="false"
                      *ngIf="!dataSource || (dataSource.loading$ | async)"></pf-loading-overlay>
</div>
<pf-support-boxs [hasRequestFeature]="false"></pf-support-boxs>