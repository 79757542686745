import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({providedIn: 'root'})
export class ApiDenoService {
  private baseUrl = 'https://one-dbe.deno.dev/api'
  // private baseUrl = 'http://localhost:4242/api'

  constructor(
    private http: HttpClient,
  ) { }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${url}`)
  }

  public post<T>(url: string, payload: any): Observable<T>  {
    return this.http.post<T>(`${this.baseUrl}/${url}`, payload)
  }

  public put<T>(url: string, payload: any): Observable<T>  {
    return this.http.put<T>(`${this.baseUrl}/${url}`, payload)
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${url}`)
  }

  public patch<T>(url: string, payload: any): Observable<T>  {
    return this.http.patch<T>(`${this.baseUrl}/${url}`, payload)
  }

}
