import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core'

@Component({
    selector: 'pf-one-tooltip',
    templateUrl: './one-tooltip.component.html',
    styleUrls: ['./one-tooltip.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
    ]
})

export class OneTooltipComponent implements OnInit, AfterViewInit {
  @HostBinding('style.max-width.px') @Input() maxWidth: number
  @HostBinding('style.width.px') @Input() width: number
  text: string | TemplateRef<any>
  interactive: boolean = false
  tpl: TemplateRef<any>
  transparent: boolean
  context: any
  constructor(
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}
