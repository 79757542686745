import { Injectable } from '@angular/core'
import { EMPTY, Observable, catchError, forkJoin, map, of, switchMap, concatMap } from 'rxjs'
import { StripeResponse, StripeProduct, StripePrice, StripeCustomer, StripeSubscription, StripePriceExpanded, StripePromotion } from '../models/cart-models'
import { ApiService } from '../../../core/services/api/api.service'
import { InteractionsKey } from '../../../shared/models/interactions.model'
import { PlansV3Identifier } from '../../../shared/models/subscription-plan.model'
import { ApiPaymentService } from '../../../core/services/api/api-payment.service'
import { ApiDenoService } from '../../../core/services/api/api-deno.service'


@Injectable({providedIn: 'root'})
export class CartApiService {
  constructor(
    private apiService: ApiService,
    private apiDenoService: ApiDenoService,
    private apiPaymentService: ApiPaymentService,
  ) { }

  getStripeProducts(): Observable<StripeResponse<StripeProduct>> {
    return this.apiDenoService.get<StripeResponse<StripeProduct>>('stripe/products')
  }

  getStripeProductById(id: string): Observable<StripeProduct> {
    return this.apiDenoService.get<StripeProduct>(`stripe/products/${id}`)
  }

  getStripePrices(): Observable<StripeResponse<StripePrice>> {
    return this.apiDenoService.get<StripeResponse<StripePrice>>('stripe/prices')
  }

  getStripePrice(priceId: string): Observable<StripePrice> {
    return this.apiDenoService.get<StripePrice>(`stripe/prices/${priceId}`)
  }

  createSession(payload: any): Observable<any> {
    return this.apiDenoService.post('stripe/checkout/sessions', payload)
  }

  updateSubscription(subscriptionId: string, payload: any): Observable<any> {
    return this.apiDenoService.post(`stripe/subscriptions/${subscriptionId}`, payload)
  }
  
  findStripePromotionCode(code: string): Observable<StripeResponse<StripePromotion>> {
    return this.apiDenoService.get<StripeResponse<StripePromotion>>(`stripe/promotion_codes?code=${code}`)
  }

  getSubscriptionsByCustomerId(customerId: string): Observable<StripeResponse<StripeSubscription>> {
    return this.apiDenoService.get<StripeResponse<StripeSubscription>>(`stripe/subscriptions?customer_id=${customerId}`)
  }

  createCustomer(payload: any): Observable<StripeCustomer> {
    return this.apiDenoService.post<StripeCustomer>('stripe/customers', payload)
  }

  getCustomerByEmail(email: string): Observable<StripeResponse<StripeCustomer>> {
    return this.apiDenoService.get<StripeResponse<StripeCustomer>>(`stripe/customers?email=${email}`)
  }

  getExpandedProductWithPrice(priceId: string): Observable<StripePriceExpanded> {
    return this.apiDenoService.get<StripePriceExpanded>(
      `stripe/prices/${priceId}?expand[]=product&expand[]=tiers`
    ).pipe(map((res) => {
      res?.tiers?.forEach((tier) => {
        if (!tier?.unit_amount && tier.unit_amount_decimal) {
          tier.unit_amount = +tier.unit_amount_decimal
        }
      })
      return res
    }))
  }

  getProductsWithPrices() {
    // Get products
    return this.getStripeProducts().pipe(
      switchMap((products) => {
        // Get prices for each product
        const priceRequests = products['data'].map((product) => {
          return this.getStripePrice(product.default_price)
        })
        return forkJoin(priceRequests).pipe(
          map((prices) => {
            products['data'].forEach((product, index) => {
              product['price'] = prices[index]
            })
            return products
          })
        )
      })
    )
  }

  getSubscriptions(email: string): Observable<StripeResponse<StripeSubscription>> {
    return this.getCustomerByEmail(email).pipe(
      switchMap((res) => {
        const customerId = res.data[0]?.id
        if (customerId) {
          return this.getSubscriptionsByCustomerId(customerId)
        } else {
          return of({data: [], has_more: false, url: '', object: ''})
        }
      }),
      catchError((err) => {
        return EMPTY
      })
    )
  }

  writeStripeInteraction(data: any): Observable<any> {
    return this.apiService.patch(`/v1/me/`, {
      interactions: {
        [InteractionsKey.Stripe]: data
      }
    })
  }

  setFreeStripePlan() {
    return this.apiPaymentService.putSubscriptions(
      PlansV3Identifier.NewCustomFree, 
      null, 
      `${window.location.href}/checkout/success?free=1&payment_gateway=stripe`, 
      null
    )
  }

  private _urlEncode(data: any): string {
    const encodedParams = new URLSearchParams();

    const isEmail = (value: any): boolean => {
      // Add your own email pattern check here if needed
      return typeof value === 'string' && value.includes('@');
    };

    const processObject = (obj: any, parentKey?: string) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const currentKey = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

        if (value instanceof Object) {
          // Recursively process nested objects
          processObject(value, currentKey);
        } else if (value instanceof Array) {
          // Handle arrays
          value.forEach((item, index) => {
            encodedParams.append(`${currentKey}[${index}]`, item);
          });
        } else if (isEmail(value)) {
          // Don't encode emails
          encodedParams.append(currentKey, value);
        } else if (typeof value === 'string' && value.startsWith('http')) {
          // Don't encode URLs
          encodedParams.append(currentKey, value);
        } else {
          // Handle simple key-value pairs
          encodedParams.append(currentKey, value);
        }
      });
    };

    processObject(data);

    return encodedParams.toString();
  }
  
}
