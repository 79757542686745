import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { finalize, Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'

@Injectable({
  providedIn: 'root'
})
export class JsonBinApiService {
  private readonly binId = '66e86187acd3cb34a88591e1'
  private readonly url = `https://api.jsonbin.io/v3/b/`
  private readonly masterKey = '$2a$10$wezcBrHltT2Wdg64Jl1C2O.q8qxvzdYdh5Z2NLmnlpinAY2/VB2ri'
  private readonly accessKeys = {
    // READ, UPDATE:
    cRUd: '$2a$10$rnZLaq4vPqk.kLCXqFVe0u1p./3fwfeBXhj.Dqar/ktCyziNBL3XC',
    // READ:
    cRud: '$2a$10$MLLpDQqYnfoazAHaNcad9ehEx43zsMYEYTwf/av2KQqOgIACRX8By',
  }

  constructor(
    private http: HttpClient,
    private store: Store<StoreState>
  ) {
  }
  
  readBin(binId: string, jsonPath?: string): Observable<any> {
    this.store.dispatch(new ShowLoading('JsonBinGet'))
    let _headers = {
      'X-Master-Key': this.masterKey,
      'X-Access-Key': this.accessKeys.cRUd,
      'X-Bin-Meta': 'false',
    }
    if (jsonPath) {
      _headers['X-JSON-Path'] = jsonPath
    }
    const headers = new HttpHeaders(_headers)
    return this.http.get(`${this.url}/${binId}/latest`, { headers }).pipe(
      finalize(() => this.store.dispatch(new HideLoading('JsonBinGet')))
    )
  }

  updateBin(binId: string, data: any): Observable<any> {
    this.store.dispatch(new ShowLoading('JsonBinGet'))
    const headers = new HttpHeaders({
      'X-Master-Key': this.masterKey,
      'X-Access-Key': this.accessKeys.cRUd,
      'X-Bin-Meta': 'false',
    })
    return this.http.put(`${this.url}/${binId}`, data, { headers }).pipe(
      finalize(() => this.store.dispatch(new HideLoading('JsonBinGet')))
    )
  }
}
