import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { LoginComponent } from './auth/login/login.component'
import { RegisterComponent } from './auth/register/register.component'
import { ResetComponent } from './auth/reset/reset.component'
import { ShopifyComponent } from './auth/shopify/shopify.component'
import { WoocommerceComponent } from './auth/woocommerce/woocommerce.component'
import { ShopifyOauthGuard } from './core/services/shopify-oauth.service'
import { ConstantContactComponent } from './auth/shopify/mail_system_integrations/constantcontact/constantcontact.component'
import { NetworkAwarePreloadingStrategyService } from './shared/services/network-aware-preloading-strategy.service'
import { CannyLoginComponent } from './auth/canny/canny.component'
import { LoginWithPasswordComponent } from './auth/with-password/with-password.component'
import { AuthGuard } from './core/guards/auth.guard'
import { ChosePlanCompleteGuard } from './core/guards/chose-plan-complete.guard'
import { NonAuthGuard } from './core/guards/non-auth.guard'
import { ScholarshipFormComponent } from './pages/scholarship-form/scholarship-form.component'
import { ShopifyLoginRedirectGuard } from './core/guards/shopify-login-redirect.guard'
// import { PhoneVerifiedGuard } from './core/guards/phone-verified.guard'

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
    canActivate: [NonAuthGuard, ShopifyLoginRedirectGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'login/canny',
    component: CannyLoginComponent,
    title: 'Signup | Canny',
    canActivate: [AuthGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'login/shopify',
    component: ShopifyComponent,
    title: 'Login | Shopify',
    canActivate: [ShopifyOauthGuard],
  },
  {
    path: 'login/with-password',
    component: LoginWithPasswordComponent,
    title: 'Login | Password',
    canActivate: [ShopifyOauthGuard],
  },
  {
    path: 'register/shopify',
    component: ShopifyComponent,
    title: 'Signup | Shopify',
    canActivate: [ShopifyOauthGuard],
  },
  {
    path: 'register/shopify/old',
    component: ShopifyComponent,
    title: 'Signup | Shopify',
    canActivate: [ShopifyOauthGuard],
  },
  {
    path: 'register/woocommerce',
    component: WoocommerceComponent,
    title: 'Signup | WooCommerce',
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'Signup',
    canActivate: [NonAuthGuard, ShopifyLoginRedirectGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'reset',
    component: ResetComponent,
    title: 'Reset | Password',
    canActivate: [NonAuthGuard],
  },
  {
    path: 'mail_system_integrations/constantcontact',
    component: ConstantContactComponent,
    title: 'Integrations | Constant Contact',
    canActivate: [AuthGuard, ChosePlanCompleteGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./shared/modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unavailable',
    loadChildren: () => import('./shared/modules/service-pages/service-pages.module').then(m => m.ServicePagesModule),
    title: 'Unavailable',
    data: { hide_navbar: true },
  },
  {
    path: 'scholarship-form',
    component: ScholarshipFormComponent,
    title: 'Onboarding | Scholarship',
    canActivate: [],
  },
  {
    path: '',
    canActivateChild: [AuthGuard, ChosePlanCompleteGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'campaigns',
  },
  { path: 'dashboard', redirectTo: 'campaigns' },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: NetworkAwarePreloadingStrategyService
})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
