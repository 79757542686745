import { CouponCodeType } from "../../pages/coupon-codes/models/coupon-code"
import { RewardsType } from "../../pages/coupon-codes/models/rewards-model"
import { BroadcastNewsletterEmailType } from "../../pages/email-builder/models/email-editor.models"

export interface BroadcastEmail {
  id?: string,
  active?: boolean,
  sent?: number,
  opened?: number,
  opened_unique?: number,
  browser_timezone?: string,
  broadcastType?: BroadcastNewsletterEmailType
  total_revenue?: number,
  clicked?: number,
  clicked_unique?: number,
  couponRequired?: boolean,
  bounced?: number,
  delivered?: number,
  spam_reports?: number,
  revenue?: number,
  name?: string,
  isBackup?: boolean,
  from_name?: string,
  preview_text?: string,
  reply_to?: string,
  reward?: {
    id?: string
    data?: any
    reward_type?: RewardsType
    title?: string
    type?: CouponCodeType
  }
  scheduled_at?: string,
  status?: {
    code: BroadcastEmailStatus
    reasons: BroadcastEmailReasons[];
  }
  [BroadcastEmailReasons.usage]?: BroadcastStatusMessages
  [BroadcastEmailReasons.billing]?: BroadcastStatusMessages
  [BroadcastEmailReasons.validity]?: BroadcastStatusMessages
  subject?: string,
  template_data?: any,
  type?: string,
  created_at?: string,
  updated_at?: string,
  address_id?: string,
  reward_id?: string,
  reward_required?: boolean,
  coupon_required?: boolean,
  shopify_group?: any,
  shopify_group_id?: string,
  shopify_segment_id?: string,
  shopify_segment?: any,
  contact_lists?: any[],
  archived?: boolean,
  credit_cost?: number,
  use_recipient_timezone?: boolean,
}

enum BroadcastEmailReasons {
  usage = 'usage',
  billing = 'billing',
  validity = 'validity'
}

interface BroadcastStatusMessages {
  status: string
  messages: string
}

export enum BroadcastEmailStatus {
  Waiting = 'waiting',
  Sending = 'sending',
  Sent = 'sent',
  Failed = 'failed',
  Archived = 'archived',
  Draft = 'draft',
  Scheduled = 'scheduled',
  Expired = 'expired',
}

export enum BroadcastEmailState {
  Off = 'Off',
  Paused = 'Paused',
  Scheduled = 'Scheduled',
  Sent = 'Sent',
  Sending = 'Sending',
  Failed = 'Failed',
  Expired = 'Expired',
  Draft = 'Draft',
}

export enum SendToType {
  list = 'list',
  group = 'group',
  segment = 'segment'
}

export interface BroadcastList {
  id: string
  name: string
  type: string
  created_at: string
  updated_at: string
  sendToType?: SendToType.list
}

export interface BroadcastGroup {
  id: number
  name: string
  sendToType?: SendToType.group | SendToType.segment
}

export interface ContactsListOptions {
  title: string
  sendToType: SendToType
  source: BroadcastList[] | BroadcastGroup[]
}
