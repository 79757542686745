export enum UserRole {
  user = 'user',
  admin = 'admin',
  support = 'support',
}

export class AdminUser {
  auth_provider_id: string
  email: string
  id: string
  token: string
  selected?: boolean
  shop_url?: string
  shopify_plan?: string
  favorite?: boolean
}
