import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin, Subscription } from 'rxjs'
import moment from 'moment'
import { HolidayPromotionModalComponent } from '../../modals/holiday-promotion-modal/holiday-promotion-modal.component'
import { AutomationTypes, HolidayTypes, promotionTagMap, promotionTypeMap } from '../models/promotion.models'
import * as _ from 'lodash'
import { CampaignService } from '../../../../core/services/campaign.service'
import { UserService } from '../../../../core/services/user.service'
import { CampaignPluginName } from '../../../models/campaign/campaign'
import {
  FeatureTypeModal,
  VoteFeatureModalComponent,
} from '../../modals/vote-feature.modal/vote-feature.modal.component'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { ApiService } from '../../../../core/services/api/api.service'
import { filter, finalize } from 'rxjs/operators'
import {
  ReceiveModalType,
  ReceiveRequestModalComponent,
} from '../../modals/receive-request.modal/receive-request.modal.component'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { UpsellService } from '../../../../pages/upsell/services/upsell.service'
import { UpsellListItem } from '../../../../pages/upsell/models/upsell-page.models'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { RouteHeaderUrl } from '../../one-header/header-navigation.model'
import { AccessFormModalComponent } from '../../../modules/jotform/components/access-form-modal/access-form-modal.component'
import { upsellAccessFormPreset } from '../../../modules/jotform/models/upsell-access-form-preset.model'
import { ThemeRequestModalComponent } from '../../modals/theme-request.modal/theme-request.modal.component'
import { CommonModule } from '@angular/common'
import { FeaturedAppsComponent } from '../components/featured-apps/featured-apps.component'
import { HolidayCardsMonthComponent } from '../components/holiday-cards-month/holiday-cards-month.component'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { UserShopType } from '../../../models/user/user-shop-type.model'
import { PromotionCards } from './promotion-cards.object'
import { MatDialog } from '@angular/material/dialog'
import { StripeLimitEnforcementService } from '../../../../core/services/enforcement/stripe-limit-enforcement.service'


@Component({
    selector: 'pf-promotion-cards',
    templateUrl: './promotion-cards.component.html',
    styleUrls: ['./promotion-cards.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      FeaturedAppsComponent,
      HolidayCardsMonthComponent,
      LoadingOverlayComponent,
    ]
})
export class PromotionCardsComponent implements OnInit, OnDestroy, AfterViewInit {
  // @HostListener('window:resize', ['$event']) onResize() {
  //   this.scaleCards()
  // }
  @Input() showHolidayCards = true
  @Input() showFeaturedApps = false
  @ViewChild('cardsContainerRef', {static: false}) cardsContainerRef: ElementRef
  @ViewChild('cardRef', {static: false}) cardRef: ElementRef

  overLimit: boolean
  subscription = new Subscription()
  today = moment().startOf('day').utc()
  visibleMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

  featuredApps = [
    {
      id: AutomationTypes.CheckoutUpsell,
      title: 'One-Click Checkout',
      description: 'Instantly Increase Average Order Value (AOV)',
      btnText: 'Get Started',
      image: '/app_assets/apps/upsell-featured-app.svg',
      imageClass: '',
      // action: this.checkoutUpsellClick.bind(this),
      action: this.createUpsellOffer.bind(this),
      badges: [{ text: 'Post-Purchase Upsell', class: 'success'}, {text: 'Post-Purchase Cross Sell', class: 'success'}],
      shopTypes: [UserShopType.ShopifyShop],
      blocked: false,
    },
    {
      id: AutomationTypes.CartRecovery,
      title: 'Abandonment Protector',
      description: 'Instantly Increase Sales Conversion Rate',
      btnText: 'Get Started',
      image: '/app_assets/apps/cart-recovery-featured-app.svg',
      imageClass: '_cart-recovery',
      badges: [{ text: 'Abandoned Cart Recovery', class: 'success'}, {text: 'Abandoned Checkout Recovery', class: 'success'}],
      shopTypes: [UserShopType.ShopifyShop],
      blocked: false,
    }
  ]

  promotionCards = new PromotionCards().cards
  upcoming2023HolidayCards = this.getHolidayCards(card => moment().isSameOrBefore(card.date, 'day') && !card.date.includes('2024'))
  upcoming2024HolidayCards = this.getHolidayCards(card => moment().isSameOrBefore(card.date, 'day') && !card.date.includes('2023'))
  passedHolidayCards = this.getHolidayCards(card => moment().isAfter(card.date, 'day'))
  show2023HolidayCards = Object.values(this.upcoming2023HolidayCards).some((cards: any[]) => cards.length > 0)
    // {
    //   id: AutomationTypes.CheckoutUpsell,
    //   class: '_checkout-upsell',
    //   text: 'Increase average order value with',
    //   title: ' one-click checkout upsell!',
    //   buttonText: 'Explore',
    //   active: {
    //     start: '2023-12-06T00:00:00.000',
    //     end: '2023-01-15T00:00:00.000',
    //   },
    //   shopifyOnly: true,
    //   time_left: 'NEW',
    //   action: this.checkoutUpsellClick.bind(this)
    // },
    // {
    //   id: AutomationTypes.CartRecovery,
    //   class: '_cart-recovery',
    //   text: 'Recover Sales with',
    //   title: 'Cart Recovery Automation!',
    //   buttonText: 'Explore',
    //   active: {
    //     start: '2023-12-06T00:00:00.000',
    //     end: '2023-12-31T00:00:00.000',
    //   },
    //   shopifyOnly: true,
    // },
    // {
    //   id: AutomationTypes.Upsell,
    //   class: '_upsell',
    //   text: 'Recommend Products with',
    //   title: 'Upsell Automation!',
    //   buttonText: 'Explore',
    //   active: {
    //     start: '2023-12-06T00:00:00.000',
    //     end: '2023-01-15T00:00:00.000',
    //   },
    //   shopifyOnly: true,
    // },
    // {
    //   id: AutomationTypes.ThankYou,
    //   class: '_thank-you',
    //   text: 'Build Brand Trust with',
    //   title: 'Thank You Email Automation',
    //   subtitle: '(New Shopify Subscribers)',
    //   buttonText: 'Explore',
    //   active: {
    //     start: '2023-02-16T00:00:00.000',
    //     end: '2023-12-31T00:00:00.000',
    //   },
    //   shopifyOnly: true,
    // }

  readonly singleCardWidth = 400
  readonly isShopifyShop = _.get(this.userService.userInfo, 'shop.type') === 'ShopifyShop'
  readonly isShopifyBudding = this.isShopifyShop && _.get(this.userService.userInfo, 'subscription.plan.payment_gateway_plan_identifier') === 'budding'
  readonly activeCampaignCount = _.get(this.userService.userInfo, 'subscription.campaigns_limit.active', 0)
  readonly currentMonth = moment().format('MMMM')
  readonly nextMonth = moment().add(1, 'months').format('MMMM')

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private campaignsService: CampaignService,
    private userService: UserService,
    private store: Store<StoreState>,
    private apiService: ApiService,
    private gettingStartedService: GettingStartedService,
    private stripeLimitEnforcementService: StripeLimitEnforcementService,
    private upsellService: UpsellService,
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.featuredApps = this.featuredApps.filter(app => !app.shopTypes || app.shopTypes.includes(this.userService.userInfo?.shop?.type))
    this.subscription.add(
      this.stripeLimitEnforcementService.feature$.pipe(
        filter(feature => feature !== null),
      ).subscribe(features => {
        if (features[RouteHeaderUrl.upsell]?.blocked) {
          const upsell = this.featuredApps?.find(app => app.id === AutomationTypes.CheckoutUpsell)
          if (upsell) {
            upsell.blocked = true
          }
          const cartRecovery = this.featuredApps?.find(app => app.id === AutomationTypes.CartRecovery)
          if (cartRecovery) {
            cartRecovery.blocked = true
          }
        }
      })
    )
  }

  ngAfterViewInit() {

  }

  getHolidayCards(filterFn) {
    const cards = {}
    Object.keys(this.promotionCards).forEach(key => {
      cards[key] = this.promotionCards[key].filter(filterFn)
    })

    return cards
  }

  showHolidayPromotionModal(promotionType: HolidayTypes | AutomationTypes) {
    const maxWidth = [AutomationTypes.ThankYou, AutomationTypes.CheckoutUpsell].includes(promotionType as AutomationTypes) ? '600px' : '890px'
    const dialog = this.dialog.open(HolidayPromotionModalComponent, {
      maxWidth,
      width: '685px',
      data: promotionType,
    })
    this.subscription.add(
      dialog.afterClosed().subscribe((value) => {
        if (value) {
          if (value.type === 'popup') {
            this.createCampaign(value.data, promotionType) // remove when BuddingActiveCampaignWarningModalComponent should be live again
          } else if (value.type === 'email') {
            this.router.navigate([`/email-editor/broadcast`], {
              queryParams: {
                type: value.data.broadcastType,
                promotion: value.data.promotion,
              },
            })
          } else if (value.type === 'redirect') {
            this.router.navigateByUrl(value.data)
          } else if (value.type === 'checkout_upsell')  {
            this.createUpsellOffer()
          }
        }
      }),
    )
  }

  createUpsellOffer() {
    this.subscription.add(this.upsellService.createUpsellOffer({name: 'Untitled'} as UpsellListItem).subscribe(res => {
      if (res && res.id) {
        this.router.navigate([`upsell/${res.id}/offer`])
        this.segmentAnalyticsService.track('Create Upsell Offer', {
          upsell: {
            id: res.id,
            name: res.name,
          }
        })
      }
    }, err => {
      this.router.navigate([`upsell`])
    }))
  }

  createCampaign(pluginName, promotionType) {
    const campaignType = promotionTypeMap[promotionType]
    const promotionTags = promotionTagMap[promotionType]
    const campaignName = {
      [CampaignPluginName.FortuneWheel]: `Gamified ${campaignType} Campaign`,
      [CampaignPluginName.CouponBoxNotification]: `Coupon ${campaignType} Campaign`,
      [CampaignPluginName.NewsLetterNotification]: `Subscription ${campaignType} Campaign`,
    }[pluginName]
    this.subscription.add(
      this.campaignsService
        .createCampaignWithPlugin(pluginName, campaignName, { promotion: promotionType }, promotionTags)
        .subscribe()
    )
  }

  daysLeft(date: string, end: string = '') {
    const difference =  moment(date, 'YYYY-MM-DD').utc().diff(this.today, 'days') as number
    const endDifference = end && moment(end, 'YYYY-MM-DD').utc().diff(this.today, 'days') as number
    let useDifference = difference
    if (difference > 30) {
      const monthsLeft = Math.floor(difference / 30).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      return `${monthsLeft} Months Left`
    }
    // if festival has end and end date hasn't passed say NOW
    if (end && difference < -1 && endDifference >= 0) {
      return 'NOW'
    } else if (end && difference < -1 && endDifference < 0) {
      useDifference = endDifference
    }
    switch (difference) {
      case 1:
        return `Tomorrow`
      case 0:
        return `Today`
      case -1:
        return `Yesterday`
      default:
        const daysLeft = difference.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
        return difference < -1 ? `${-daysLeft} Days Ago` : `${daysLeft} Days Left`
    }
  }

  weeksLeft(date: string) {
    const start = moment(date, 'YYYY-MM-DD')
    const end = moment(date).add(7, 'days')
    const now = moment()
    if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
      return `This Week`
    } else if (start.isAfter(now)) {
      const difference = start.diff(this.today, 'week') as number
      if (difference === 0) {
        return `Next Week`
      }
      return this.daysLeft(date)
    } else if (end.isBefore(now)) {
      const difference =  end.diff(this.today, 'week') as number
      if (difference === 0) {
        return `Last Week`
      }
      return this.daysLeft(end.toISOString())
    }
  }

  public checkoutUpsellClick() {
    // const userUpsellEligible = this.userService.userInfo?.upsell_eligible
    // if (!userUpsellEligible) {
    //   this.openUpsellForm()
    //   return
    // }
    this.showHolidayPromotionModal(AutomationTypes.CheckoutUpsell)
  }

  public openUpsellForm() {
    const upsellDialog = this.dialog.open(AccessFormModalComponent, {
      width: '700px',
      data: {
        preset: upsellAccessFormPreset
      },
    })

    this.subscription.add(
      upsellDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.subscription.add(forkJoin([
            this.userService.updateUserUpsell(true),
            this.apiService.post(`/v1/me/canny/feature_requests/upsell_one_click_checkout/vote_and_comment`, {value: ''})
          ]).subscribe(() => {
            this.router.navigateByUrl(RouteHeaderUrl.upsell)
          }))
        }
      }))
  }

  public openRequestFeatureModal(type: FeatureTypeModal, isWaitingList = false)  {
    const status = this.gettingStartedService.getFeatureVoteStatus()
    if (status[type]?.completed && status[type]?.url) {
      this.openVotedModal(status[type].url, status[type].voteCount || 0, isWaitingList, type)
      return
    }

    const requestDialog = this.dialog.open(VoteFeatureModalComponent, {
      width: '440px',
      data: { featureType: type, isWaitingList },
    })
    this.subscription.add(
      requestDialog.afterClosed().subscribe((data) => {
        if (data && data.request) {
          const req = data.request
          const { type, description } = req
          const payload = {
            value: description
          }
          this.store.dispatch(new ShowLoading('voteAndCommentToCanny'))
          this.subscription.add(this.apiService.post(`/v1/me/canny/feature_requests/${type}/vote_and_comment`, payload)
            .pipe(finalize(() => this.store.dispatch(new HideLoading('voteAndCommentToCanny'))))
            .subscribe(
              res => {
                if (res && res.created === true) {
                  const url = _.get(res, 'data.url')
                  const voteCount = _.get(res, 'data.score')
                  this.openVotedModal(url, voteCount, isWaitingList, type)
                  this.updateVoteStatus('upsell_one_click_checkout', url, voteCount)
                } else {
                  console.error('Something went wrong to post request to canny.')
                }
              },
              error => {
                console.error('Unable post request to canny', error)
              }
            ))
        }
      })
    )
  }

  openVotedModal(url, voteCount, isWaitingList, featureType) {
    this.dialog.open(ReceiveRequestModalComponent, {
      width: '560px',
      data: {
        type: isWaitingList ? ReceiveModalType.WaitingList : ReceiveModalType.Vote,
        url,
        voteCount,
        featureType
      }
    })
  }

  updateVoteStatus(key, url, voteCount) {
    this.subscription.add(this.gettingStartedService.updateFeatureVoteStatus({[key]: {
      completed: true,
      voteCount,
      url
    }}).subscribe())
  }

  // scaleCards() {
  //   clearTimeout(this.debounceTimeout)
  //
  //   this.debounceTimeout = setTimeout(
  //     () => {
  //       this.dynamicScaleStyles = this.calculateDynamicScaleStyles(this.cardsWidth)
  //     },100)
  //
  //   setTimeout(() => {
  //     const gap = 10
  //     const containerHeight = this.cardRef.nativeElement.getBoundingClientRect().height
  //     this.elRef.nativeElement.style.height = `${containerHeight + gap}px`
  //   }, 200)
  // }
  //
  // calculateDynamicScaleStyles(elWidth) {
  //   if (this.cardsContainerRef.nativeElement.offsetWidth > elWidth) {
  //     return {
  //       transform: 'scale(1)',
  //     }
  //   }
  //   const scaleValue = this.cardsContainerRef.nativeElement.offsetWidth / (elWidth + this.cardsMargin)
  //   return {
  //     transform: `scale(${scaleValue})`,
  //   }
  // }

  public requestDesign() {
    this.segmentAnalyticsService.track('Clicked Request New Theme')
    const userId = _.get(this.userService.userInfo, 'id')
    const dialog = this.dialog.open(ThemeRequestModalComponent, {
      width: '880px',
      data: { userId },
      panelClass: 'theme-request-modal'
    })
    this.subscription.add(
      dialog.afterClosed().subscribe((data) => {
        if (data && data.request) {
          const req = data.request
          const { description, imageUrl, name, type } = req
          const payload = {
            title: name,
            type,
            details: description,
            image_urls: imageUrl.map(res => res.url)
          }
          this.store.dispatch(new ShowLoading('creatPostToCanny'))
          this.subscription.add(this.apiService.post(`/v1/me/create_canny_post`, payload)
            .pipe(finalize(() => this.store.dispatch(new HideLoading('creatPostToCanny'))))
            .subscribe(
              res => {
                if (res && res.created === true) {
                  this.segmentAnalyticsService.track('Submitted Theme Request')
                  const url = _.get(res, 'data.url')
                  this.dialog.open(ReceiveRequestModalComponent, {
                    width: '560px',
                    data: {
                      type: ReceiveModalType.Theme,
                      url
                    }
                  })
                } else {
                  console.error('Something went wrong to post request to canny.')
                }
              },
              error => {
                console.error('Unable post request to canny', error)
              }
            ))
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
