import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { FilterSelectModule } from '../../shared/modules/filter-select/filter-select.module'
import { UpsellPageRoutingModule } from './upsell-page-routing.module'
import { UpsellPageComponent } from './upsell-page.component'
import { UpsellService } from './services/upsell.service'
import { UpsellBuilderStepsService } from './services/upsell-builder-steps.service'
import { UpsellCardsComponent } from './components/upsell-cards/upsell-cards.component'
import { UpsellBuilderComponent } from './components/upsell-builder/upsell-builder.component'
import { SideNavigationBarModule } from '../../shared/modules/side-navigation-bar/side-navigation-bar.module'
import { UpsellOfferStepComponent } from './components/upsell-builder/steps/offer/upsell-offer-step.component'
import { FooterbarNavigationModule } from '../../core/modules/footerbar-navigation/footerbar-navigation.module'
import { ProductsSelectorModule } from '../../shared/modules/products-selector/products-selector.module'
import { UpsellDesignStepComponent } from './components/upsell-builder/steps/design/upsell-design-step.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { UpsellResolverService } from './services/upsell-resolver.service'
import { UpsellPreviewComponent } from './components/preview/upsell-preview.component'
import { SavableUpsellGuard } from './services/savable-upsell.guard'
import { MatDaterangeModule } from '../../shared/modules/mat-daterange/mat-daterange.module'
import { TranslateUpsellPipe } from './pipes/translate-upsell.pipe'
import { QuillModule } from 'ngx-quill'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ScrollInvalidInputDirective } from '../../shared/directives/scroll-invalid-input.directive'
import { OneConfettiDirective } from '../../shared/directives/one-confetti.directive'
import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe'
import { SwitchBoxComponent } from '../../shared/components/controls/switch-box/switch-box.component'
import { OneIconComponent } from '../../shared/components/one-icon/one-icon.component'
import { LoadingOverlayComponent } from '../../shared/components/loading-overlay/loading-overlay.component'
import { VideoTutorialTriggerButtonComponent } from '../tutorials/standalone/trigger-button/video-tutorial-trigger-button.component'
import { MessageBoxComponent } from '../../shared/components/message-box/message-box.component'
import { NoTableDataComponent } from '../../shared/components/no-table-data/no-table-data.component'
import { SupportBoxsComponent } from '../../shared/components/support-boxs/support-boxs.component'
import { MatSelectModule } from '@angular/material/select'
import { MatOptionModule } from '@angular/material/core'
import { OneAIDirective } from '../../shared/directives/one-ai.directive'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatMenuModule } from '@angular/material/menu'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button'
import { LimitBlockerComponent } from '../../core/components/limit-blocker/limit-blocker.component'

@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FilterSelectModule,
    UpsellPageRoutingModule,
    SideNavigationBarModule,
    FooterbarNavigationModule,
    NgSelectModule,
    ProductsSelectorModule,
    DragDropModule,
    MatDaterangeModule,
    MatCheckboxModule,
    MatMenuModule,
    SwitchBoxComponent,
    QuillModule.forRoot(),
    ScrollInvalidInputDirective,
    OneConfettiDirective,
    CapitalizePipe,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatTooltipModule,
    MatButtonModule,
    OneIconComponent,
    MessageBoxComponent,
    LoadingOverlayComponent,
    VideoTutorialTriggerButtonComponent,
    NoTableDataComponent,
    SupportBoxsComponent,
    OneAIDirective,
    LimitBlockerComponent,
  ],
  declarations: [
    UpsellPageComponent,
    UpsellCardsComponent,
    UpsellBuilderComponent,
    UpsellOfferStepComponent,
    UpsellDesignStepComponent,
    UpsellPreviewComponent,
    TranslateUpsellPipe,
  ],
  providers: [
    UpsellService,
    UpsellBuilderStepsService,
    UpsellResolverService,
    SavableUpsellGuard,
    CapitalizePipe,
    TranslateUpsellPipe,
  ],
  exports: [
    UpsellPreviewComponent,
    TranslateUpsellPipe,
  ]
})

export class UpsellPageModule {
}
