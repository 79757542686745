import { RouteHeaderUrl } from "../../../shared/components/one-header/header-navigation.model"

/* In case we'd want to add a product that has no tiers */
export enum PageConfigProductType {
  tiers = 'tiers',
  solo = 'solo',
}

// export enum ProductPageConfigId {
//   tiktok = 'tiktok',
//   shopify = 'shopify',
//   emails = 'emails',
//   sms = 'sms',
//   textReviews = 'text-reviews',
//   photoReviews = 'photo-reviews',
//   videoReviews = 'video-reviews',
//   coupons = 'coupons',
//   aiAgents = 'ai-agents',
//   aiCopilot = 'ai-copilot',
//   aiSidekick = 'ai-sidekick',
//   giftCards = 'gift-cards',
//   checkoutUpsell = 'checkout-upsell',
//   popUpsSubscribeForms = 'pop-ups-subscribe-forms',
//   popUpsAnnouncements = 'pop-ups-announcements',
//   popUpsSocialProof = 'pop-ups-social-proof',
// }

export enum StripeEmailPlan {
  free = 'Feature Preview',
  basic = 'Basic',
  pro = 'Pro',
}

export interface ProductTierNgSelectOption {
  value: number
  from: number
  unit_amount: number
  up_to: number
  label: string
}

export interface StripeError {
  code: string
  doc_url: string
  message: string
  param: string
  type: string
  request_log_url: string
}

/* This is the core model that represent the cart data locally */
export interface PageConfigCardModel {
  title: string
  sections: PageConfigSectionModel[]
}

export interface PageConfigCartProduct {
  priceId: string
  productId: string
  name: StripeEmailPlan
  price?: number
  discountedPrice?: number
  marketing_features: {name: string}[]
  tiers: ProductTierNgSelectOption[]
  hideBadge?: boolean
  customBadge?: string
  customBadgeClass?: string
  customPrice?: string
  customPeriod?: string
  description: string
  metadata?: any
  unit_label?: string
  unit_amount: number
  active: boolean
  quantity?: number
  trialEnd?: Date
}

export interface PageConfigTier {
  active?: boolean
  quantity?: number
  name?: string
  price?: number
  marketing_features?: {name: string}[]
  priceId?: string
  productId?: string
  usagePlan?: StripePriceTier
  tiers?: ProductTierNgSelectOption[]
}

/* Section is a representation of Emails, Popups, SMS and so on */
export interface PageConfigSectionModel {
  id: RouteHeaderUrl
  url: string
  backUrl: string
  name: string
  cardName: string
  customSubtitle?: string
  hideButton?: boolean
  expanded?: boolean
  skipCardUnitLabel?: boolean
  required?: boolean
  customButton?: string
  customButtonActionId?: string
  customButtonActionExtras?: any
  // active: boolean
  productType: PageConfigProductType
  // subtitleSuffix: string
  selectedProduct: PageConfigCartProduct
  purchasedProduct: PageConfigCartProduct
  products: PageConfigCartProduct[]
}


export interface StripeResponse<T> {
  data: Array<T>
  has_more: boolean
  url: string
  object: string
}

export interface StripeSubscriptionItem {
  id: string
  object: "subscription_item"
  billing_thresholds: any
  created: number
  metadata: any
  plan: StripePlan
  price: StripePrice | StripePriceExpanded
  quantity: number
  subscription: string
  tax_rates: any[]
}

export interface StripeSubscription {
  id: string
  object: "subscription"
  application: any
  application_fee_percent: any
  automatic_tax: {
    enabled: boolean
    liability: null
  }
  billing_cycle_anchor: number
  billing_cycle_anchor_config: any
  billing_thresholds: any
  cancel_at: any
  cancel_at_period_end: boolean
  canceled_at: any
  cancellation_details: {
    comment: any
    feedback: any
    reason: null
  }
  collection_method: string
  created: number
  currency: string
  current_period_end: number
  current_period_start: number
  customer: string
  days_until_due: any
  default_payment_method: string
  default_source: any
  description: any
  discount: any
  ended_at: any
  invoice_settings: {
    account_tax_ids: any
    issuer: {
      type: string
    }
  }
  items: {
    object: "list"
    data: StripeSubscriptionItem[]
    has_more: boolean
    total_count: number
    url: string
  }
  latest_invoice: string
  livemode: boolean
  metadata: any
  next_pending_invoice_item_invoice: any
  on_behalf_of: any
  pause_collection: any
  payment_settings: {
    payment_method_options: {
      acss_debit: any
      bancontact: any
      card: {
        network: any
        request_three_d_secure: string
      }
      customer_balance: any
      konbini: any
      us_bank_account: null
    }
    payment_method_types: any
    save_default_payment_method: string
  }
  pending_invoice_item_interval: any
  pending_setup_intent: any
  pending_update: any
  plan: {
    id: string
    object: "plan"
    active: boolean
    aggregate_usage: any
    amount: any
    amount_decimal: any
    billing_scheme: string
    created: number
    currency: string
    interval: string
    interval_count: number
    livemode: boolean
    metadata: any
    nickname: any
    product: string
    tiers_mode: string
    transform_usage: any
    trial_period_days: any
    usage_type: string
  }
  quantity: number
  schedule: any
  start_date: number
  status: string
  test_clock: any
  transfer_data: any
  trial_end: any
  trial_settings: {
    end_behavior: {
      missing_payment_method: string
    }
  }
  trial_start: null
}

export interface StripeCustomer {
  id: string
  object: "customer"
  address: {
      city: any
      country: string
      line1: any
      line2: any
      postal_code: any
      state: any
  }
  balance: number
  created: number
  currency: string
  default_source: any
  delinquent: boolean
  description: any
  discount: any
  email: string
  invoice_prefix: string
  invoice_settings: {
      custom_fields: any
      default_payment_method: any
      footer: any
      rendering_options: any
  }
  livemode: boolean
  metadata: any
  name: string
  next_invoice_sequence: number
  phone: any
  preferred_locales: string[]
  shipping: any
  tax_exempt: string
  test_clock: any
}

export interface StripePlan {
  id: string
  object: "plan"
  active: boolean
  aggregate_usage: any
  amount: any
  amount_decimal: any
  billing_scheme: string
  created: number
  currency: string
  interval: string
  interval_count: number
  livemode: boolean
  metadata: any
  nickname: any
  product: string
  tiers_mode: string
  transform_usage: any
  trial_period_days: any
  usage_type: string
}

export interface StripePrice {
  id: string
  object: "price"
  active: boolean
  billing_scheme: any
  created: number
  currency: string
  custom_unit_amount: any
  livemode: boolean
  lookup_key: any
  metadata: any
  nickname: any
  product: string
  recurring: {
    aggregate_usage: any
    interval: any
    interval_count: number
    trial_period_days: any
    usage_type: any
  }
  tax_behavior: any
  tiers_mode: any
  transform_quantity: any
  type: any
  unit_amount: any
  unit_amount_decimal: string
}

export interface StripePriceTier {
  flat_amount: number
  flat_amount_decimal: string
  unit_amount: number
  unit_amount_decimal: string
  up_to: number
}

export interface StripePriceExpanded {
  id: string
  object: "price"
  active: boolean
  billing_scheme: any
  created: number
  currency: string
  custom_unit_amount: any
  livemode: boolean
  lookup_key: any
  metadata: any
  nickname: any
  product: StripeProduct
  recurring: {
    aggregate_usage: any
    interval: any
    interval_count: number
    trial_period_days: any
    usage_type: any
  }
  tax_behavior: any
  tiers: StripePriceTier[]
  tiers_mode: any
  transform_quantity: any
  type: any
  unit_amount: any
  unit_amount_decimal: string
}

export interface StripeProduct {
  id?: string
  object?: "product"
  active?: boolean
  attributes?: any[]
  created?: number
  default_price?: string
  description?: string
  marketing_features?: {name: string}[]
  images?: any[]
  livemode?: boolean
  metadata?: any
  name?: string
  package_dimensions?: any
  shippable?: any
  statement_descriptor?: any
  tax_code?: any
  type?: any
  unit_label?: any
  updated?: number
  url?: string
  prices?: StripePrice[]
}

export interface StripePromotionCoupon {
  id: string
  object: string
  amount_off: null
  created: number
  currency: null
  duration: string
  duration_in_months: number
  livemode: boolean
  max_redemptions: null
  metadata: any
  name: null
  percent_off: number
  redeem_by: null
  times_redeemed: number
  valid: boolean
}

export interface StripePromotionRestrictions {
  first_time_transaction: boolean
  minimum_amount: null
  minimum_amount_currency: null
}

export interface StripePromotion {
  id: string
  object: string
  active: boolean
  code: string
  coupon: StripePromotionCoupon
  created: number
  customer: null
  expires_at: null
  livemode: boolean
  max_redemptions: null
  metadata: any
  restrictions: StripePromotionRestrictions
  times_redeemed: number
}

export interface StripeJotformCheckoutPayload {
  [JotformCartField.id]?: string
  [JotformCartField.user_id]: string
  [JotformCartField.user_email]: string
  [JotformCartField.errored]?: boolean
  
  [JotformCartField.emails_payed]?: string
  [JotformCartField.emails_plan]?: string
  [JotformCartField.emails_quantity]?: number
  [JotformCartField.emails_price_id]?: string
  [JotformCartField.emails_cost_per_unit]?: number

  [JotformCartField.popups_subscribe_payed]?: string
  [JotformCartField.popups_subscribe_plan]?: string
  [JotformCartField.popups_subscribe_quantity]?: number
  [JotformCartField.popups_subscribe_price_id]?: string
  [JotformCartField.popups_subscribe_cost_per_unit]?: number
  
  [JotformCartField.sms_payed]?: string
  [JotformCartField.sms_plan]?: string
  [JotformCartField.sms_quantity]?: number
  [JotformCartField.sms_price_id]?: string
  [JotformCartField.sms_cost_per_unit]?: number

}

export enum JotformCartField {
  id = 'id',
  user_id = 'user_id',
  user_email = 'user_email',
  errored = 'errored',

  emails_payed = 'emails_payed',
  emails_plan = 'emails_plan',
  emails_quantity = 'emails_quantity',
  emails_price_id = 'emails_price_id',
  emails_cost_per_unit = 'emails_cost_per_unit',

  popups_subscribe_payed = 'popups_subscribe_payed',
  popups_subscribe_plan = 'popups_subscribe_plan',
  popups_subscribe_quantity = 'popups_subscribe_quantity',
  popups_subscribe_price_id = 'popups_subscribe_price_id',
  popups_subscribe_cost_per_unit = 'popups_subscribe_cost_per_unit',

  sms_payed = 'sms_payed',
  sms_plan = 'sms_plan',
  sms_quantity = 'sms_quantity',
  sms_price_id = 'sms_price_id',
  sms_cost_per_unit = 'sms_cost_per_unit',
}


export const jotformQidMap = {
  [JotformCartField.user_id]: "3",
  [JotformCartField.user_email]: "9",
  [JotformCartField.emails_quantity]: "13",
  [JotformCartField.emails_price_id]: "14",
  [JotformCartField.sms_quantity]: "15",
  [JotformCartField.sms_price_id]: "16",
  [JotformCartField.emails_cost_per_unit]: "19",
  [JotformCartField.sms_cost_per_unit]: "20",
  [JotformCartField.popups_subscribe_payed]: "22",
  [JotformCartField.popups_subscribe_plan]: "23",
  [JotformCartField.popups_subscribe_quantity]: "24",
  [JotformCartField.popups_subscribe_price_id]: "25",
  [JotformCartField.popups_subscribe_cost_per_unit]: "26",
  [JotformCartField.emails_plan]: "27",
  [JotformCartField.sms_payed]: "28",
  [JotformCartField.emails_payed]: "29",
  [JotformCartField.errored]: "30",
  [JotformCartField.sms_plan]: "31"
}


export type CartLocalPayedStatuses = {
  [key in JotformCartField]: CartLocalPaymentStatus
}

/* 
  Jotform store the payment status as a string, this enum is to make it easier to work with the data.
  Jotform values are: 'true', 'false' or empty
  Maps like so:
  'true' -> success
  'false' -> pending
  '' -> none
*/
export enum CartLocalPaymentStatus {
  success = 'success',
  pending = 'pending',
  none = 'none',
}
