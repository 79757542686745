import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { SavableGuard } from './guards/savable.guard'
// import { GlobalErrorModalComponent } from '../shared/components/modals/global-error/global-error.component'
import { UnsavedDataGuardService } from './guards/unsaved-data.guard'
import { DashboardSettingsService } from './services/dashboard-settings.service'
import { AdminGuard } from './guards/admin.guard'
import { AuthGuard } from './guards/auth.guard'
import { ChosePlanCompleteGuard } from './guards/chose-plan-complete.guard'
import { ChosePlanIncompleteGuard } from './guards/chose-plan-incomplete-guard.service'
import { NonAuthGuard } from './guards/non-auth.guard'
import { HttpErrorInterceptor } from './interceptors/http.error.interceptor'
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor'
import { AdminUserTokenService } from './services/admin-user-token.service'
import { AnalyticsService } from './services/analytics.service'
import { ApiCampaignService } from './services/api/api-campaign.service'
import { ApiGettingStartedService } from './services/api/api-getting-started.service'
import { ApiPaymentService } from './services/api/api-payment.service'
import { ApiSettingsService } from './services/api/api-settings.service'
import { ApiUserService } from './services/api/api-user.service'
import { ApiService } from './services/api/api.service'
import { AuthService } from './services/auth.service'
import { BrandingService } from './services/branding.service'
import { CampaignUrlService } from './services/campaign-url.service'
import { CampaignService } from './services/campaign.service'
import { ErrorsService } from './services/errors.service'
import { ExternalSaveService } from './services/external-save.service'
import { FileStorageService } from './services/file-storage.service'
import { GettingStartedService } from './services/getting-started.service'
import { IntegrationsService } from './services/integrations.service'
import { LanguageService } from './services/language.service'
import { LoadingOverlayService } from './services/loading-overlay.service'
import { PaymentService } from './services/payment.service'
import { PhoneNumberService } from './services/phone-number.service'
import { SlackSupporService } from './services/slack-support.service'
import { SystemNotificationsService } from './services/system-notifications.service'
import { UrlService } from './services/url.service'
import { UserService } from './services/user.service'
import { WheelService } from './services/wheel.service'
import { PrePlansSurveyIncompleteGuard } from './guards/pre-plans-survey-incomplete-guard.service'
import { OnboardingAppsUndoneGuard } from './guards/onboarding-apps-undone.guard'
import { UserInfoResolver } from './services/user-info.resolver'
import { ShopifyService } from './services/shopify.service'
import { UnlayerDragAndDropGuard } from './guards/unlayer-dnd.guard'
import { EmbeddedAppsGuard } from './guards/embedded-apps.guard'
import { EmbeddedAppsAvailabilityService } from './services/embedded-apps-availability.service'
import { ShopifyLoginRedirectGuard } from './guards/shopify-login-redirect.guard'
import { CrispTimeoutMessageService } from './services/crisp-timeout-message.service'
// import { PhoneVerifiedGuard } from './guards/phone-verified.guard'
import { ApiJotformService } from './services/api/api-jotform.service'
import { PlatformOnboardingDoneGuard } from './guards/platorm-onboarding-done.guard'
import { PlatformOnboardingUndoneGuard } from './guards/platorm-onboarding-undone.guard'
import { ActiveAppCampaignService } from './services/active-app-campaign.service'
import { UpgradePlanHelperService } from './services/upgrade-plan-helper.service'
import { SafeLocalStorageService } from './services/safe-local-storage.service'
import { CrispService } from './services/crisp.service'
import { PreviousUrlService } from './services/previous-url.service'
import { RoleBasedInterceptor } from './interceptors/role-based.interceptor'
import { MatDialogModule } from '@angular/material/dialog'

const CORE_SERVICES = [
  ApiService,
  ApiCampaignService,
  ApiUserService,
  ApiGettingStartedService,
  ApiPaymentService,
  ApiSettingsService,
  ApiJotformService,

  AdminUserTokenService,
  AnalyticsService,
  AuthService,
  AuthGuard,
  SavableGuard,
  NonAuthGuard,
  PaymentService,
  UserService,
  CampaignService,
  CampaignUrlService,
  LoadingOverlayService,
  SlackSupporService,
  SystemNotificationsService,
  ErrorsService,
  FileStorageService,
  IntegrationsService,
  WheelService,
  GettingStartedService,
  PhoneNumberService,
  UrlService,
  UnlayerDragAndDropGuard,
  LanguageService,
  ExternalSaveService,
  BrandingService,
  DashboardSettingsService,
  UserInfoResolver,
  SafeLocalStorageService,
  PreviousUrlService,

  ShopifyService,
  EmbeddedAppsAvailabilityService,
  CrispService,
  CrispTimeoutMessageService,
  ActiveAppCampaignService,
  UpgradePlanHelperService,
]
const GUARDS = [
  AuthGuard,
  AdminGuard,
  NonAuthGuard,
  ShopifyLoginRedirectGuard,
  ChosePlanCompleteGuard,
  ChosePlanIncompleteGuard,
  UnsavedDataGuardService,
  PrePlansSurveyIncompleteGuard,
  OnboardingAppsUndoneGuard,
  EmbeddedAppsGuard,
  // PhoneVerifiedGuard,
  PlatformOnboardingDoneGuard,
  PlatformOnboardingUndoneGuard,
]

@NgModule({
  imports: [CommonModule, MatDialogModule],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RoleBasedInterceptor, multi: true },
    ...CORE_SERVICES,
    ...GUARDS,
  ],
  declarations: [],
})
export class CoreModule {

  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    }
  }
}
