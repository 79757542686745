@if (blockerResolved) {
  @if (featureBlocked) {
    @if (blockerMessage) {
      <ng-container [ngTemplateOutlet]="blockerMessage"></ng-container>
    } @else { 
      <div class="limit-blocker">
        <div class="limit-blocker-content">
          <p>You've reached the limit for this feature. Please upgrade to continue.</p>
          <button class="one-button xs filled blue" [routerLink]="['/settings/billing/subscriptions']">Upgrade</button>
        </div>
      </div>
    }
  } @else {
    <ng-content></ng-content>
  }
}
