
<div class="SectionTitle mb-0 d-flex">
  @if (backBtnUrl && pageTitleService.backBtnVisible()) {
    <a [routerLink]="backBtnUrl" class="pf-options-button mr-8px" *ngIf="backBtnUrl && pageTitleService.backBtnVisible()">
      <one-icon name="arrow-back-outline"></one-icon>
    </a>
  }
  @if (!backBtnUrl && clickMethod) {
    <a (click)="clickMethod()" class="pf-options-button mr-8px">
      <one-icon name="arrow-back-outline"></one-icon>
    </a>
  }
  <ng-container *ngIf="pageTitleService.titleVisible()">{{title}}</ng-container>
</div>