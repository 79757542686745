<div class="featured-apps">
  <div
    class="featured-apps-inner"
    *ngFor="let app of featuredApps; let i = index;"
    [@animImageSlider]="slideControl[i]"
  >

    <div class="featured-apps-wrapper">
      <div>
        <div class="featured-apps-header">Featured Apps</div>
        <div class="featured-apps-title">{{app.title}}</div>
        <div class="featured-apps-description">{{app.description}}</div>
        <div class="featured-apps-badges" *ngIf="app.badges">
          <ng-container *ngFor="let badge of app.badges">
            <span class="pf-badge mr-2 {{badge.class}}">{{badge.text}}</span>
          </ng-container>
        </div>
        @if (!app.blocked) {
          <button (click)="onGetStartedClick(app)"
                  class="pf-button outline blue featured-apps-btn">{{app.btnText}}</button>
        } @else {
          <button class="pf-button outline black featured-apps-btn" (click)="showUpgradePopup()">
            <one-icon name="lock-closed-outline" class="mr-1"></one-icon>
            Limit reached
          </button>
        }
      </div>
      <img class="featured-apps-image" [ngClass]="app?.imageClass" [src]="app.image" alt="">

    </div>
  </div>
  <button [class.d-none]="featuredAppsCounter === 0"
          class="btn-prev"
          (click)="slide('right')">
    <div class="icon-wrapper">
      <span class="prev-icon"></span>
    </div>
  </button>
  <button [class.d-none]="featuredAppsCounter === featuredApps.length - 1"
          class="btn-next"
          (click)="slide('left')">
    <div class="icon-wrapper">
      <span class="next-icon"></span>
    </div>
  </button>
</div>
