import { Component, OnDestroy, OnInit } from '@angular/core'
import { UserService } from '../../../../../core/services/user.service'
import { CommonModule } from '@angular/common'
import { ActiveCampaignDropdownComponent } from '../active-campaign-dropdown/active-campaign-dropdown.component'
import { ActiveAppCampaignService } from '../../../../../core/services/active-app-campaign.service'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { GettingStartedService } from '../../../../../core/services/getting-started.service'
import { keyValuePairKey } from '../../../../models/key-value-pair.model'
import { Router, RouterModule } from '@angular/router'
import { RouteHeaderUrl } from '../../header-navigation.model'
import { UsagePlanMetricType } from '../../../../../pages/plans/models/usage-plan-metric.model'
import { BehaviorSubject, Subscription } from 'rxjs'
import { ShopifyService } from '../../../../../core/services/shopify.service'
import { AdminUserTokenService } from '../../../../../core/services/admin-user-token.service'
import moment from 'moment'
import { PlatformOnboardingStage, PlatformOnboardingStatus } from '../../../../models/platform-onboarding.model'
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { ApiJotformService } from '../../../../../core/services/api/api-jotform.service'
import { UserUsageInfo } from '../../../../../store/user/user.state'
import _ from 'lodash'
import { SMSService } from '../../../../services/sms.service'
import { PaymentService } from '../../../../../core/services/payment.service'
import { PlansV3Identifier } from '../../../../models/subscription-plan.model'

@Component({
  selector: 'one-main-nav-stats',
  templateUrl: 'one-main-nav-stats.component.html',
  styleUrls: ['./one-main-nav-stats.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OneIconComponent,
    ActiveCampaignDropdownComponent,
  ],
})

export class OneMainNavStatsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  routeHeaderUrl = RouteHeaderUrl
  isShopifyShop = false
  activeCampaignCount$ = this.activeAppCampaignService.activeCampaignCount$

  // metricTitle: string
  // metricCount$: BehaviorSubject<number>
  contactsCount$ = this.shopifyService.contactsCount$
  subscribersCount$ = this.shopifyService.subscribersCount$
  activeKnownCustomersCount$ = this.shopifyService.activeKnownCustomersCount$
  planLabel: string

  planProgress: number
  currentMonthUsage: number
  currentMonthUsageLimit: number

  isAdminAsUser = false
  adminAsUser: any

  isShopifyBudding = false
  newPricingEligible = false
  showUniqueVisitors = false
  overLimit = false
  userSMSEligible = false
  showSMSCredits = false
  showPlanProgressPercentage = false

  campaignsLimit = 1
  activeCampaignsCount = 0
  smsCredits = 0
  campaignsPaused = false

  constructor(
    private activeAppCampaignService: ActiveAppCampaignService,
    private gettingStartedService: GettingStartedService,
    private adminUserTokenService: AdminUserTokenService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private apiJotformService: ApiJotformService,
    private userService: UserService,
    private smsService: SMSService,
    private paymentService: PaymentService,
    private shopifyService: ShopifyService,
    private router: Router,
  ) {
    this.isShopifyShop = this.userService.isShopifyShop
    this.isShopifyBudding = this.isShopifyShop && this.userService.isLegacyBuddingPlanUser
    this.showSMSCredits = this.isShopifyShop
    this.smsCredits = this.userService.smsCredits

    if (this.userService?.userInfo?.subscription) {
      this.showUniqueVisitors = this.userService.isLegacyPaidPlanUser
      if (this.userService?.userInfo?.shop?.usage_charge_active) {
        this.showUniqueVisitors = true
        const freeCount = _.max([
          this.userService?.userInfo?.subscription.plan.max_unique_vists,
          this.userService?.userInfo?.subscription.max_unique_visits_override
        ]) + this.userService?.userInfo?.subscription.rewarded_visitor_count
        const paidCount = this.userService?.userInfo.shop.usage_charge_capped_visitors
        this.currentMonthUsageLimit = freeCount + paidCount
      } else {
        this.currentMonthUsageLimit = this.userService?.userInfo?.subscription?.unique_visits_limit
      }
      this.overLimit = this.currentMonthUsage > this.currentMonthUsageLimit
      if (this.userService?.userInfo?.subscription?.plan) {
        this.planLabel = this.paymentService.getSubscriptionPlanSettingsLabel(this.userService?.userInfo?.subscription?.plan)
      }
    }
    this.newPricingEligible = this.isShopifyShop && (this.userService.newPricingEligible || this.userService.isNewFreePlanUser || this.userService.isNewV3PlanUser)
    this.userSMSEligible = this.smsService.userIsEligible(this.userService?.userInfo)

    // const metric = this.newPricingEligible ? UsagePlanMetricType.ActiveKnownCustomers : this.userService?.userInfo?.subscription?.usage_plan?.metric
    // switch (metric) {
    //   case UsagePlanMetricType.Customers:
    //     this.metricCount$ = this.contactsCount$
    //     this.metricTitle = 'Customer\nProfiles'
    //   break
    //   case UsagePlanMetricType.Subscribers:
    //     this.metricCount$ = this.subscribersCount$
    //     this.metricTitle = 'Subscribers'
    //   break
    //   case UsagePlanMetricType.ActiveKnownCustomers:
    //     this.metricCount$ = this.activeKnownCustomersCount$
    //     this.metricTitle = 'Active\nCustomers'
    //   default:
    // }
  }

  ngOnInit() {
    const userInfo = this.userService?.userInfo
    this.subscription.add(
      this.adminUserTokenService.asObservable()
        .subscribe(val => {
          this.isAdminAsUser = val ? true : false
          this.adminAsUser = val
        }),
    )

    this.subscription.add(
      this.userService.userUsageInfo$.subscribe((next: UserUsageInfo) => {
        if (next?.subscription) {
          const max_unique_vists = _.get(next, 'subscription.unique_visits_limit', 0)
          this.planProgress = Math.round((next?.current_month_usage / max_unique_vists * 100) * 100) / 100
          this.currentMonthUsage = next?.current_month_usage
        }
      }),
    )
    this.updateJotformIfNeeded(userInfo)

    this.campaignsPaused = this.userService.invoiceDueDatePassed
  }

  navigateToPlansPage(event) {
    event.preventDefault()
    const isNewPlansPageVersion = this.userService.isNewPlanUser || this.newPricingEligible || this.isShopifyBudding
    const isStripeCustomer = this.userService.isStripeCustomer
    if (isNewPlansPageVersion) {
      if (isStripeCustomer) {
        this.router.navigate([`/${this.routeHeaderUrl.settings}/${this.routeHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`])
      } else {
        this.router.navigate([`/${this.routeHeaderUrl.settings}/${this.routeHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`])
      }
    } else {
      this.router.navigate([this.routeHeaderUrl.choose_plan])
    }
  }

  updateJotformIfNeeded(userInfo) {
    // don't update jotfom/platform onboarding if this is admin logged in for demo
    if (this.isAdminAsUser) {
      return
    }
    const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
    const prefilled5Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled5)
    const prefilled6Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled6)
    const startedPlanTimestamp = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.SlectedPlanTimestamp)
    let approvedPlanTimestamp = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.ApprovedPlanTimestamp)
    // consider paid approved for old users
    const approvedPaidPlan = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.ApprovedPaidPlan) || moment(this.userService?.userInfo?.created_at).isBefore('2022-12-31')
    // if this is the first redirect to our app after plan is approved on shopify or redirect after paid user upgrades to paid plan
    if (
      startedPlanTimestamp &&
      (!approvedPlanTimestamp || (!approvedPaidPlan && this.userService.isNewPremiumPlanUser)) &&
      userInfo.onboarding?.chose_plan &&
      this.userService?.userInfo?.platform_onboarding_status !== PlatformOnboardingStatus.Completed
    ) {
      approvedPlanTimestamp = Date.now()
      const pairs: any = {
        [keyValuePairKey.ApprovedPlanTimestamp]: approvedPlanTimestamp
      }
      if (this.userService.isNewPlanUser) {
        pairs[keyValuePairKey.ApprovedPaidPlan] = true
      }
      this.subscription.add(
        this.gettingStartedService.updateKeyValuePairs(pairs).subscribe()
      )
      this.subscription.add(
        this.userService.updatePlatformOnboarding({stage: PlatformOnboardingStage.CampaignSetup}).subscribe()
      )
      this.segmentAnalyticsService.identify(this.userService?.userInfo?.id, {
        onboarding_status: PlatformOnboardingStatus.Started,
        onboarding_stage: PlatformOnboardingStage.CampaignSetup,
        onboarding_plan_charge_approved: this.userService.isNewPlanUser,
      })
      if (submissionId && prefilled5Id) {
        const plan = this.userService?.userInfo?.subscription?.plan
        const jotFormPayload: any = {
          [prefilled5Id]: {
            field_1: plan.name || '', // plan nam
            field_2: `${+plan.price?.fractional / plan.price?.currency?.subunit_to_unit}` || '', // plan pricee
            field_3: 'True',
            field_4: `${Math.floor((approvedPlanTimestamp - startedPlanTimestamp) / 1000) || 0}` // how many seconds elapsed before approval
          },
          new: '1',
        }
        if (prefilled6Id) {
          jotFormPayload[prefilled6Id] = {
            field_2: PlatformOnboardingStage.CampaignSetup
          }
        }
        // wrap in try catch to make sure this doesn't block anything
        try {
            this.subscription.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
        } catch (e) {
        }
      }
    }
  }

  navigateToUsage(ev: MouseEvent) {
    ev.preventDefault()
    this.router.navigate([`/${this.routeHeaderUrl.choose_plan}`])
  }

  navigateToCampaigns(ev: MouseEvent) {
    ev.preventDefault()
    this.router.navigate([`/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}`])
  }

  onAddCreditsClick() {
    this.router.navigate([RouteHeaderUrl.sms_credits_page])
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
