import { ElementRef, Injectable } from '@angular/core'
import { BrowserVendor, ShopifyService } from '../../core/services/shopify.service'
import * as logRocketJs from '!raw-loader!../../../app_assets/scripts/LogRocket.min.js'


@Injectable()
export class IframeHelperService {
  readonly isSafari = this.shopifyService.currentBrowser === BrowserVendor.Safari

  constructor(
    private shopifyService: ShopifyService,
  ) {
  }

  public getIframeUrl(iFrameRef: ElementRef, source: string) {
    const getBlobURL = (code, type) => {
      const blob = new Blob([code], { type })
      return URL.createObjectURL(blob)
    }

    if (this.isSafari) {
      const iFrameDoc = iFrameRef?.nativeElement?.contentWindow?.document
      this.writeIframePreviewHTML(iFrameDoc, source)
      return null
    } else {
      return getBlobURL(source, 'text/html')
    }
  }

  public writeIframePreviewHTML(iFrameDoc, source) {
    iFrameDoc?.open()
    iFrameDoc?.write(source)
    // Insert logrocket script if not present
    if (!iFrameDoc?.querySelector('#logrocket')) {
      const logRocketScript = iFrameDoc?.createElement('script')
      logRocketScript.id = 'logrocket'
      logRocketScript.innerHTML = logRocketJs.default
      iFrameDoc?.body?.appendChild(logRocketScript)
      // also insert the init script
      const logRocketInitScript = iFrameDoc?.createElement('script')
      logRocketInitScript.innerHTML = `
        LogRocket.init('morpheus/one', {
          rootHostname: 'one.store',
          mergeIframes: true,
          parentDomain: "${window.location.origin}"
        })
      `
      iFrameDoc?.body?.appendChild(logRocketInitScript)
    }
    iFrameDoc?.close()
  }

}
