<h4 mat-dialog-title *ngIf="data" [ngClass]="data.headerClass ? data.headerClass : ''">
  {{data.title}}
  @if (!data.disableCancel) {
    <button class="mat-dialog-close" (click)="dialogRef.close(null)">
      <span>&times;</span>
    </button>
  }
</h4>
<div *ngIf="data" [ngClass]="data.contentClass ? data.contentClass : 'mb-2'">
  <p [class.mb-2]="data.component" *ngIf="data.preComponentHTML" [innerHtml]="data.preComponentHTML"></p>
  <ng-container #dynamicComponentContainer></ng-container>
  <p [class.mt-2]="data.component" *ngIf="data.text">{{data.text}}</p>
  <div *ngIf="data.html" [innerHtml]="data.html"></div>
</div>
<div mat-dialog-actions *ngIf="data" style="gap: 15px;" [ngClass]="data.footerClass ? data.footerClass : 'mb-0 justify-content-center'">
  @if (!data.disableCancel) {
    <button
      *ngIf="data.cancelButton"
      [class]="data.cancelButton.classes"
      (click)="dialogRef.close(false)"
    >{{data.cancelButton.text}}</button>
  }
  <button
    [class]="data.acceptButton.classes"
    (click)="dialogRef.close(true)"
  >{{data.acceptButton.text}}</button>
  @if (data.extraButton) {
    <button
      [class]="data.extraButton.classes"
      (click)="dialogRef.close(data.extraButton.value)"
    >{{data.extraButton.text}}</button>
  }
</div>
