import { RouteHeaderUrl } from "../../../../shared/components/one-header/header-navigation.model";

export enum EnforcementWhiteListFeature {
  emails = 'e',
  subscribe_forms = 'sf',
  announcements = 'an',
  social_proof = 'sp',
  upsell = 'u',
  coupons = 'c',
  gift_cards = 'gc',
  sms = 's',
  akc = 'ak',
}

export const enforcementWLMappings = {
  [RouteHeaderUrl.emails]: EnforcementWhiteListFeature.emails,
  [RouteHeaderUrl.subscribe_forms]: EnforcementWhiteListFeature.subscribe_forms,
  [RouteHeaderUrl.announcements]: EnforcementWhiteListFeature.announcements,
  [RouteHeaderUrl.social_proof]: EnforcementWhiteListFeature.social_proof,
  [RouteHeaderUrl.upsell]: EnforcementWhiteListFeature.upsell,
  [RouteHeaderUrl.coupons]: EnforcementWhiteListFeature.coupons,
  [RouteHeaderUrl.gift_cards]: EnforcementWhiteListFeature.gift_cards,
  [RouteHeaderUrl.sms]: EnforcementWhiteListFeature.sms,
  akc: EnforcementWhiteListFeature.akc,
}

export interface EnforcementWhiteListItem {
  e: boolean
  t: number
  d: number
}

/**
 * Enforcement White List 
 * Determines if a feature is allowed to be used even when the account has exceeded the limit
 * @e: boolean - if the feature is whitelisted
 * @t: number - time to live in seconds (in case we want to expire the whitelist)
 * @d: number - date of update
 */
export interface EnforcementWhiteList {
  [EnforcementWhiteListFeature.emails]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.subscribe_forms]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.announcements]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.social_proof]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.upsell]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.coupons]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.gift_cards]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.sms]: EnforcementWhiteListItem
  [EnforcementWhiteListFeature.akc]: EnforcementWhiteListItem
}
