<div class="one-tooltip-component" [class._transparent]="transparent">
  @if (!tpl) {
    <div [innerHTML]="text"></div>
  }
  @if (tpl && context) {
    <ng-template [ngTemplateOutlet]="tpl" [ngTemplateOutletContext]="context"></ng-template>
  }
  @if (tpl && !context) {
    <ng-template [ngTemplateOutlet]="tpl"></ng-template>
  }
</div>
