import { EnvironmentName } from './models/environment-name.model'

export const environment = {
  name: EnvironmentName.PRODUCTION,
  production: true,
  server_running: false,
  apiBaseURL: 'https://api.morpheusone.dev',
  widgetURL: 'https://cdn.one.store/javascript/dist/1.0/jcr-widget.js',
  widgetPreviewURL: 'https://cdn.one.store/javascript/dist/1.0/jcr-widget-preview.js',
  adminPortalBaseURL: 'https://admin.one.store',
  hostname: 'morpheusone.dev',
  title: 'Morpheus One | Increase Your Conversion',
  shopify: {
    clientId: '',
    embedded: false
  },
  firebase: {
    apiKey: 'AIzaSyAoyG9txOQCz_KQ1kmyNwu6zCvn0grVU14',
    authDomain: 'fireauth.one.store',
    databaseURL: 'https://prooffactor.firebaseio.com',
    projectId: 'prooffactor',
    storageBucket: 'cdn.prooffactor.com',
    messagingSenderId: '326019435744',
    cdnUrl: 'cdn.one.store',
  },
  stripe: {
    publishableKey: 'pk_live_N1s4gP0XxK0lRh3YxcVCsY9J',
  },

  signUp: {
    autoGenerateCredentials: false,
    baseEmail: 'production@prooffactor.com',
    basePass: '123456',
  },

  gettingStarted: {
    active: true,
    detectPollingInterval: 4000,
  },
  // fullStoryOrg: '17DNWX',
  logRocketID: 'morpheus/one',
  googleAnaltyicsToken: 'UA-122699112-1',
  segmentApiKey: 'MtkizSrsRUWWM281lVxaoZuZiiaVQfzM',
  hCaptchaSiteKey: '32158274-6630-4132-956d-b8934055fe54',
  jotFormApiKey: 'adec81eea78a10f70896bdbd5521d53f',
  shopifyEmbedPixelId: 'e2660a3c-4398-468f-b8cb-e4e470225df5'
}
