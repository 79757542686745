import { RouteHeaderUrl } from "../../../shared/components/one-header/header-navigation.model"
import { PageConfigCardModel, PageConfigProductType, StripeEmailPlan } from "../models/cart-models"

export class ProductPageConfig {
  private readonly TEST_MODE = false

  // Has to be stored separately because we handle the free plan differently
  private production_freePlanPriceIds = {
    [RouteHeaderUrl.emails]: 'price_1O8AeuLayR839eGhCE6zSKmc',
    [RouteHeaderUrl.sms]: 'price_1O8AysLayR839eGhxjvmaLqJ',
    [RouteHeaderUrl.subscribe_forms]: 'price_1O8AjCLayR839eGhpk8KS1Hg',
    [RouteHeaderUrl.announcements]: 'price_1O8Bc9LayR839eGhe2bGMfMd',
    [RouteHeaderUrl.social_proof]: 'price_1O8BdaLayR839eGhkXfraXlo',
    [RouteHeaderUrl.upsell]: 'price_1O8BR7LayR839eGhFIXU1vAp',
    [RouteHeaderUrl.coupons]: 'price_1O8B7lLayR839eGhYUHbH94E',
    [RouteHeaderUrl.gift_cards]: 'price_1O8BIdLayR839eGhg6KYvffj',
    [RouteHeaderUrl.ai_sidekick]: 'price_1O8BmsLayR839eGhBW2gRkoK',
    [RouteHeaderUrl.ai_copilot]: 'price_1P9VDNLayR839eGhU4joTZy4',
    [RouteHeaderUrl.tiktok]: 'price_1P9VnQLayR839eGhvXaRvl7I',
    [RouteHeaderUrl.shopify]: 'price_1PBL9qLayR839eGhJTl03fwL',

    [RouteHeaderUrl.textReviews]: 'price_1PC2r3LayR839eGhRYVVFuw9',
    [RouteHeaderUrl.photoReviews]: 'price_1PC2rdLayR839eGhHhZPvlkW',
    [RouteHeaderUrl.videoReviews]: 'price_1PC2rpLayR839eGhhRo22sKa',
  }

  private test_freePlanPriceIds = {
    [RouteHeaderUrl.tiktok]: 'price_1P9rbtLayR839eGhrtlHb9Mw',
    [RouteHeaderUrl.emails]: 'price_1OmfqtLayR839eGh1BIF2vJB',
    [RouteHeaderUrl.shopify]: 'price_1PByXILayR839eGh8jVyku57',
  }

  private production_productsPageConfig: PageConfigCardModel[] = [
    {
      title: 'AI Agents',
      sections: [
        {
          id: RouteHeaderUrl.ai_sidekick,
          url: `${RouteHeaderUrl.ai_sidekick}/${RouteHeaderUrl.ai_agents}/plans`,
          backUrl: '../../',
          name: 'AI Sidekick',
          cardName: 'Ai Agent',
          hideButton: true,
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.production_freePlanPriceIds[RouteHeaderUrl.ai_sidekick],
              productId: 'prod_Ow3usUNpGqgUux',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        },
        // {
        //   id: RouteHeaderUrl.ai_copilot,
        //   url: `${RouteHeaderUrl.ai_copilot}/plans`,
        //   backUrl: '../../',
        //   name: 'AI Co-Pilot',
        //   cardName: 'Ai Agent',
        //   hideButton: true,
        //   customButton: 'Join Beta',
        //   customButtonActionId: 'requestFeature:one_store_copilot',
        //   customButtonActionExtras: {isWaitingList: true},
        //   productType: PageConfigProductType.tiers,
        //   selectedProduct: null,
        //   purchasedProduct: null,
        //   products: [
        //     {
        //       priceId: this.production_freePlanPriceIds[RouteHeaderUrl.ai_copilot],
        //       productId: 'prod_PzUBgj4p9GpdrF',
        //       unit_amount: 0,
        //       name: StripeEmailPlan.free,
        //       marketing_features: null,
        //       customBadge: 'Beta',
        //       customBadgeClass: '_yellow',
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //   ]
        // },
      ]
    }, 
    {
      title: 'Checkouts',
      sections: [
        {
          id: RouteHeaderUrl.upsell,
          url: `${RouteHeaderUrl.checkout}/${RouteHeaderUrl.upsell}/plans`,
          backUrl: '../../',
          name: 'One-Click Upsell Pages',
          cardName: 'Checkout',
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.production_freePlanPriceIds[RouteHeaderUrl.upsell],
              productId: 'prod_Ow3YPVq4umAV5b',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
            {
              priceId: 'price_1P6S2yLayR839eGhBv1iZg9y',
              productId: 'prod_PEw4fh9GMi0crH',
              unit_amount: 0,
              name: StripeEmailPlan.basic,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        },
      ]
    },
    {
      title: 'Messaging',
      sections: [
        {
          id: RouteHeaderUrl.emails,
          url: `${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/plans`,
          backUrl: '../../',
          name: 'Emails',
          cardName: 'Messaging',
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.production_freePlanPriceIds[RouteHeaderUrl.emails],
              productId: 'prod_Ow2kZDUyu83k1H',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
            {
              priceId: 'price_1P6qyFLayR839eGh5eqfupXY',
              productId: 'prod_OtnFpGHn8nmV15',
              unit_amount: 0,
              name: StripeEmailPlan.basic,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        },
        {
          id: RouteHeaderUrl.sms,
          url: `${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/plans`,
          backUrl: '../../',
          name: 'SMS',
          customSubtitle: '250 credits (one-time gift)',
          cardName: 'Messaging',
          hideButton: true,
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.production_freePlanPriceIds[RouteHeaderUrl.sms],
              productId: 'prod_Ow35KyVpYu4tOa',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              customBadge: 'Usage-Based',
              customPrice: '$0.01',
              customPeriod: 'per credit',
              hideBadge: false,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        }
      ]
    },
    {
      title: 'Pop Ups',
      sections: [
        {
          id: RouteHeaderUrl.subscribe_forms,
          url: `${RouteHeaderUrl.popups}/${RouteHeaderUrl.subscribe_forms}/plans`,
          backUrl: '../../',
          name: 'Pop Ups & Social Proof',
          cardName: 'Pop Up',
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.production_freePlanPriceIds[RouteHeaderUrl.subscribe_forms],
              productId: 'prod_Ow2ofBbNV5wlBr',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
            {
              priceId: 'price_1P7Q4iLayR839eGh7yH2rnrU',
              productId: 'prod_OvkHiYVO1AX7PM',
              unit_amount: 0,
              name: StripeEmailPlan.basic,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ],
        },
        // {
        //   id: RouteHeaderUrl.announcements,
        //   url: `${RouteHeaderUrl.popups}/${RouteHeaderUrl.announcements}/plans`,
        //   backUrl: '../../',
        //   name: 'Announcements',
        //   cardName: 'Pop Up',
        //   productType: PageConfigProductType.tiers,
        //   selectedProduct: null,
        //   purchasedProduct: null,
        //   products: [
        //     {
        //       priceId: this.production_freePlanPriceIds[RouteHeaderUrl.announcements],
        //       productId: 'prod_Ow3j9NXXcyPrEb',
        //       unit_amount: 0,
        //       name: StripeEmailPlan.free,
        //       marketing_features: null,
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //     {
        //       priceId: 'price_1P6SOTLayR839eGhFJLJwPqU',
        //       productId: 'prod_PYtCoPMggyK3Ux',
        //       unit_amount: 0,
        //       name: StripeEmailPlan.basic,
        //       marketing_features: null,
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //   ],
        // },
        // {
        //   id: RouteHeaderUrl.social_proof,
        //   url: `${RouteHeaderUrl.popups}/${RouteHeaderUrl.social_proof}/plans`,
        //   backUrl: '../../',
        //   name: 'Social Proof Notifications',
        //   cardName: 'Pop Up',
        //   productType: PageConfigProductType.tiers,
        //   selectedProduct: null,
        //   purchasedProduct: null,
        //   products: [
        //     {
        //       priceId: this.production_freePlanPriceIds[RouteHeaderUrl.social_proof],
        //       productId: 'prod_Ow3lBjO8oNgRGm',
        //       unit_amount: 0,
        //       name: StripeEmailPlan.free,
        //       marketing_features: null,
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //     {
        //       priceId: 'price_1P6SWWLayR839eGhxYLKw8Hp',
        //       productId: 'prod_PDZv6j8l0hAMXb',
        //       unit_amount: 0,
        //       name: StripeEmailPlan.basic,
        //       marketing_features: null,
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //   ],
        // },
      ]
    },
    // {
    //   title: 'Rewards',
    //   sections: [
    //     {
    //       id: RouteHeaderUrl.coupons,
    //       url: `${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}/plans`,
    //       backUrl: '../../',
    //       name: 'Coupons',
    //       cardName: 'Reward',
    //       productType: PageConfigProductType.tiers,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.coupons],
    //           productId: 'prod_Ow3EcMzgdywa1z',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //         {
    //           priceId: 'price_1P7CmgLayR839eGhmF4bdrZr',
    //           productId: 'prod_P1CJ29obGk12Px',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.basic,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //     {
    //       id: RouteHeaderUrl.gift_cards,
    //       url: `${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}/plans`,
    //       backUrl: '../../',
    //       name: 'Gift Cards',
    //       cardName: 'Reward',
    //       productType: PageConfigProductType.tiers,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.gift_cards],
    //           productId: 'prod_Ow3Pxk9s4VA1oC',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //         {
    //           priceId: 'price_1P7CxuLayR839eGhWlMJmGct',
    //           productId: 'prod_Px78jalYRBJInH',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.basic,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //   ]
    // },
    // {
    //   title: 'Reviews',
    //   sections: [
    //     {
    //       id: RouteHeaderUrl.textReviews,
    //       url: ``,
    //       backUrl: '../../',
    //       name: 'Text Reviews',
    //       cardName: 'Reviews',
    //       hideButton: true,
    //       customButton: 'Join Beta',
    //       customButtonActionId: 'requestFeature:products_text_reviews',
    //       customButtonActionExtras: {isWaitingList: false},
    //       productType: PageConfigProductType.tiers,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.textReviews],
    //           productId: 'prod_Q2758xngy28fNu',
    //           unit_amount: 0,
    //           customBadge: 'Beta',
    //           customBadgeClass: '_yellow',
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //     {
    //       id: RouteHeaderUrl.photoReviews,
    //       url: ``,
    //       backUrl: '../../',
    //       name: 'Photo Reviews',
    //       cardName: 'Reviews',
    //       hideButton: true,
    //       customButton: 'Join Beta',
    //       customButtonActionId: 'requestFeature:products_photo_reviews',
    //       customButtonActionExtras: {isWaitingList: false},
    //       productType: PageConfigProductType.tiers,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.photoReviews],
    //           productId: 'prod_Q275TbQnVhZM1a',
    //           unit_amount: 0,
    //           customBadge: 'Beta',
    //           customBadgeClass: '_yellow',
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //     {
    //       id: RouteHeaderUrl.videoReviews,
    //       url: ``,
    //       backUrl: '../../',
    //       name: 'Video Reviews',
    //       cardName: 'Reviews',
    //       hideButton: true,
    //       customButton: 'Join Beta',
    //       customButtonActionId: 'requestFeature:products_video_reviews',
    //       customButtonActionExtras: {isWaitingList: false},
    //       productType: PageConfigProductType.tiers,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.videoReviews],
    //           productId: 'prod_Q276sQwwSIlf60',
    //           unit_amount: 0,
    //           customBadge: 'Beta',
    //           customBadgeClass: '_yellow',
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //   ]
    // },
    // {
    //   title: 'Sales Channels',
    //   sections: [
    //     {
    //       id: RouteHeaderUrl.shopify,
    //       url: `${RouteHeaderUrl.sales_channels}/${RouteHeaderUrl.shopify}/plans`,
    //       backUrl: '../../',
    //       name: 'Shopify Stores',
    //       cardName: 'Sales Channel',
    //       hideButton: false,
    //       expanded: false,
    //       productType: PageConfigProductType.tiers,
    //       skipCardUnitLabel: true,
    //       selectedProduct: null,
    //       purchasedProduct: null,
    //       products: [
    //         {
    //           priceId: this.production_freePlanPriceIds[RouteHeaderUrl.shopify],
    //           productId: 'prod_Q1NvUWouVgHa4P',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.free,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //         {
    //           priceId: 'price_1P9WeDLayR839eGhfbYvLk7Z',
    //           productId: 'prod_PzUkzhFnncN5kX',
    //           unit_amount: 0,
    //           name: StripeEmailPlan.basic,
    //           marketing_features: null,
    //           tiers: null,
    //           description: null,
    //           active: false,
    //         },
    //       ]
    //     },
    //     // {
    //     //   id: RouteHeaderUrl.tiktok,
    //     //   url: `${RouteHeaderUrl.sales_channels}/${RouteHeaderUrl.tiktok}/plans`,
    //     //   backUrl: '../../',
    //     //   name: 'TikTok Stores',
    //     //   cardName: 'Sales Channel',
    //     //   hideButton: true,
    //     //   customButton: 'Join Beta',
    //     //   customButtonActionId: 'requestFeature:sales_channel_tiktok_store',
    //     //   customButtonActionExtras: {isWaitingList: true},
    //     //   productType: PageConfigProductType.tiers,
    //     //   selectedProduct: null,
    //     //   purchasedProduct: null,
    //     //   products: [
    //     //     {
    //     //       priceId: this.production_freePlanPriceIds[RouteHeaderUrl.tiktok],
    //     //       productId: 'prod_PzUmldt6fvyQwI',
    //     //       unit_amount: 0,
    //     //       customBadge: 'Beta',
    //     //       customBadgeClass: '_yellow',
    //     //       name: StripeEmailPlan.free,
    //     //       marketing_features: null,
    //     //       tiers: null,
    //     //       description: null,
    //     //       active: false,
    //     //     },
    //     //   ]
    //     // },
    //   ]
    // },
  ]

  private test_productsPageConfig: PageConfigCardModel[] = [

    {
      title: 'Messaging',
      sections: [
        {
          id: RouteHeaderUrl.emails,
          url: `${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/plans`,
          backUrl: '../../',
          name: 'Emails',
          cardName: 'Messaging',
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.test_freePlanPriceIds[RouteHeaderUrl.emails],
              productId: 'prod_PbteewsVyLL5YH',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
            {
              priceId: 'price_1OtCYJLayR839eGhLDrP4nbu',
              productId: 'prod_PZGQwDlJeLVEEP',
              unit_amount: 0,
              name: StripeEmailPlan.basic,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        },
      ]
    },
    {
      title: 'Sales Channels',
      sections: [
        {
          id: RouteHeaderUrl.shopify,
          url: `${RouteHeaderUrl.sales_channels}/${RouteHeaderUrl.shopify}/plans`,
          backUrl: '../../',
          name: 'Shopify Stores',
          cardName: 'Sales Channel',
          hideButton: true,
          expanded: true,
          productType: PageConfigProductType.tiers,
          selectedProduct: null,
          purchasedProduct: null,
          products: [
            {
              priceId: this.test_freePlanPriceIds[RouteHeaderUrl.shopify],
              productId: 'prod_Q22cxxQlDn7Al4',
              unit_amount: 0,
              name: StripeEmailPlan.free,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
            {
              priceId: 'price_1P9rYYLayR839eGhzdazPSLz',
              productId: 'prod_PzrHfNnms0BMCm',
              unit_amount: 0,
              name: StripeEmailPlan.basic,
              marketing_features: null,
              tiers: null,
              description: null,
              active: false,
            },
          ]
        },
        // {
        //   id: RouteHeaderUrl.tiktok,
        //   url: `${RouteHeaderUrl.sales_channels}/${RouteHeaderUrl.tiktok}/plans`,
        //   backUrl: '../../',
        //   name: 'TikTok Stores',
        //   cardName: 'Sales Channel',
        //   hideButton: true,
        //   customButton: 'Join Beta',
        //   customButtonActionId: 'requestFeature:sales_channel_tiktok_store',
        //   customButtonActionExtras: {isWaitingList: true},
        //   productType: PageConfigProductType.tiers,
        //   selectedProduct: null,
        //   purchasedProduct: null,
        //   products: [
        //     {
        //       priceId: this.test_freePlanPriceIds[RouteHeaderUrl.tiktok],
        //       productId: 'prod_PzrKWOi46hDhf4',
        //       unit_amount: 0,
        //       customBadge: 'Beta',
        //       customBadgeClass: '_yellow',
        //       name: StripeEmailPlan.free,
        //       marketing_features: null,
        //       tiers: null,
        //       description: null,
        //       active: false,
        //     },
        //   ]
        // },
      ]
    },
  ]

  // FIXME:
  public stripePricingPaths = {
    onboardingNew: 'onboarding/signup/new/subscriptions/custom',
    onboardingReturning: 'onboarding/signup/returning/subscriptions/custom',
    billing: `${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`,
  }

  public get freePlanPriceIds(): { [key: string]: string } {
    return this.TEST_MODE ? this.test_freePlanPriceIds : this.production_freePlanPriceIds
  }

  public get config(): PageConfigCardModel[] {
    return this.TEST_MODE ? this.test_productsPageConfig : this.production_productsPageConfig
  }
}
