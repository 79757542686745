import { Injectable } from '@angular/core'
import { EnforcementWhiteList } from '../models/enforcement-control.model'
import { finalize, map, Observable, of } from 'rxjs'
import { StoreState } from '../../../../store/store.state'
import { Store } from '@ngrx/store'
import { ApiService } from '../../../../core/services/api/api.service'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { UserService } from '../../../../core/services/user.service'

@Injectable({providedIn: 'root'})
export class EnforcementWlService {

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
    private userService: UserService,
  ) { }

  getEnforcementWhiteList(): Observable<EnforcementWhiteList> {
    return this.userService.readInteractions().pipe(
      map((interactions) => {
        if (interactions.e) {
          return interactions.e
        } else {
          return null
        }
      })
    )
  }
  
  updateEnforcementWhitelist(wl: EnforcementWhiteList): Observable<EnforcementWhiteList> {
    this.store.dispatch(new ShowLoading('EnforcementWLUpdate'))
    return this.apiService.patch('/v1/me', {
      interactions: {
        e: wl
      }
    }).pipe(
      finalize(() => this.store.dispatch(new HideLoading('EnforcementWLUpdate')))
    )
  }
}