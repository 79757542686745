import { Injectable } from '@angular/core'
import useAppBridge, { AppBridgeState, ClientApplication } from "@shopify/app-bridge"
import { isShopifyEmbedded } from '@shopify/app-bridge-utils'
import { Fullscreen, History, Redirect } from '@shopify/app-bridge/actions'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router'

export enum BrowserVendor {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Edge = 'Edge',
  IE = 'IE',
  EdgeChromium = 'EdgeChromium',
  Opera = 'Opera',
  Brave = 'Brave',
  Other = 'Other',
}

export enum OSVendor {
  Windows = 'Windows',
  Mac = 'Mac',
  Linux = 'Linux',
  Other = 'Other',
}

@Injectable({providedIn: 'root'})

export class ShopifyService {

  public app: ClientApplication<AppBridgeState>
  public redirect: Redirect.Redirect
  public history: History.History
  public fullScreenWasEnabled
  private _fullscreen: Fullscreen.Fullscreen
  public contactsCount$ = new BehaviorSubject<number>(null)
  public subscribersCount$ = new BehaviorSubject<number>(null)
  public activeKnownCustomersCount$ = new BehaviorSubject<number>(null)
  public customersCount$ = new BehaviorSubject<number>(null)
  public highEmailActivity$ = new BehaviorSubject<boolean>(null)

  public fullScreen$ = new BehaviorSubject<boolean>(false)
  shopifyAppUrl: string

  public allowedStandalonePlans = [
    // Shopify Store // Non-Profits
    'npo_lite',
    'npo_full',
    // Shopify Store // Enterprise Businesses
    'shopify_plus' ,
    'plus_partner_sandbox',
    // Shopify Store // Medium Businesses
    'business',
    'professional',
    'unlimited',
    'retail',
    'custom',
    // Shopify Store // Small Businesses
    'grandfather',
    'basic',
    'starter',
    'starter_2022',
    'trial',
    'paid_trial',
  ]

  public allowedStandaloneAccountsIds = [
    '51af1538-a298-4676-8b18-7963cfce6040',
  ]

  constructor(
    private router: Router,
  ) {
    if (this.isEmbedded && !this.isStorybook) {
      const params = (new URL(document.location.href)).searchParams
      const host = params.get('host')
      const shopify_no_redirect = params.get('shopify_no_redirect')

      this.app = useAppBridge({
        apiKey: environment.shopify.clientId,
        host: host,
        forceRedirect: !shopify_no_redirect,
      })
      this.redirect = Redirect.create(this.app)
      this.history = History.create(this.app)

      this.shopifyAppUrl = this.app?.hostOrigin + '/admin' + '/apps/' + environment?.shopify?.clientId + '/app'


      this._fullscreen = Fullscreen.create(this.app)
    }
  }

  public get unlayerCanWork(): boolean {
    const excludeOS = [OSVendor.Mac]
    const excludeBrowser = [BrowserVendor.Edge, BrowserVendor.EdgeChromium, BrowserVendor.Chrome, BrowserVendor.Brave]
    // If we're on a Mac, and are using one of specified browsers unlayer will not work in embedded mode
    return !(
      this.isEmbedded &&
      excludeOS.includes(this.currentOS) &&
      excludeBrowser.includes(this.currentBrowser)
    )
  }

  public get currentOS(): OSVendor {
    const v = navigator.platform.toLowerCase()
    switch (true) {
      case v.indexOf("win") > -1:
        return OSVendor.Windows
      case v.indexOf("mac") > -1:
        return OSVendor.Mac
      case v.indexOf("linux") > -1:
        return OSVendor.Linux
      default:
        return OSVendor.Other
    }
  }

  public get currentBrowser(): BrowserVendor {
    const ua = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case ua.indexOf("edge") > -1:
        return BrowserVendor.Edge
      case ua.indexOf("edg/") > -1:
        return BrowserVendor.EdgeChromium
      case ua.indexOf("opr") > -1 && !!window['opr']:
        return BrowserVendor.Opera
      case ua.indexOf("chrome") > -1 && !!window.navigator['brave']:
        return BrowserVendor.Brave
      case ua.indexOf("chrome") > -1 && !!window['chrome']:
        return BrowserVendor.Chrome
      case ua.indexOf("trident") > -1:
        return BrowserVendor.IE
      case ua.indexOf("firefox") > -1:
        return BrowserVendor.Firefox
      case ua.indexOf("safari") > -1:
        return BrowserVendor.Safari
      default:
        return BrowserVendor.Other
    }
  }

  public get akc() {
    return this.activeKnownCustomersCount$.getValue()
  }

  public get whiteListMktgUserIds() {
    // For those users we show mktg email no matter what // Backup for emergencies
    return ['3ad5117c-dfab-48ae-97cd-5e23b1941d94', // Bella Rose
            '65ea074f-de6d-4482-8bd6-b717caf5947d', // electric frireplaces depot
            '5e15c26e-5181-47ba-b213-2c29f258f858', // asayashouten Japan
            'acb7a3f9-7fa3-4016-89b9-4fbebccb0da5', // xtool France
            '682b984f-abec-4f9c-a5b4-4c08918232cf',
            'e77479bc-c2f5-4236-8afa-6ffa9a994754',
            '428e7344-633b-416e-822f-1ae29cb612fa']
  }

  public get timezoneBasedScheduleUserIds() {
    return [
      'e68ea21d-15bf-4e57-a9f0-cbb82cf43ec1', // jaki-london.myshopify.com
      // Accounts for testing:
      '51af1538-a298-4676-8b18-7963cfce6040', // ihor-production.myshopify.com
      '4816d8e4-d825-461b-9351-c9876312a402', // ihor-staging.myshopify.com
      '0e0dff41-4b27-4bff-b883-df430d1c7a28', // somadroptable-staging-002
      '00a06411-1621-4a49-9439-b6eb6add0e92', // somaupsl-prod-001
      '3b54280c-2954-4c1a-97c4-8a44a06334c8', // steph-prod-0001
    ]
  }

  public get annotationUserIds() {
    return [
      'e68ea21d-15bf-4e57-a9f0-cbb82cf43ec1', // jaki-london.myshopify.com
      '058eab56-348e-4eb7-9f03-ca46dc418ed5', // bstuartcustomdesigns.myshopify.com
      'c63bc28f-4bbe-48d3-a3db-03a96fa4e7bd', // blu-spero.myshopify.com
      // Accounts for testing:
      '51af1538-a298-4676-8b18-7963cfce6040', // ihor-production.myshopify.com
      '0e0dff41-4b27-4bff-b883-df430d1c7a28', // somadroptable-staging-002
      '00a06411-1621-4a49-9439-b6eb6add0e92', // somaupsl-prod-001
      '3b54280c-2954-4c1a-97c4-8a44a06334c8', // steph-prod-0001
      '19143e5a-ca35-458e-b664-6aab1f8faa49', // prod-nikita
    ]
  }

  public get canUseMktgEmail() {
    return this.akc < 2500
  }

  public get isEmbedded(): boolean {
    // return isShopifyEmbedded()
    // Using another method to check if we're embedded
    // because isShopifyEmbedded() has an additional check that might fail in rare cases
    // Storybookapi is here to avoid errors when rendering our components inside of the Storybook's iframe
    return window.top !== window.self && !this.isStorybook
  }

  public get isStorybook(): boolean {
    try {
      return !!window?.top?.['__STORYBOOKAPI__'];
    } catch (error) {
      // Handle potential cross-origin error gracefully
      return false;
    }
  }

  public redirectToUrl(url: string) {
    if (this.isEmbedded) {
      this.redirect.dispatch(Redirect.Action.APP, `/app${url}`)
    } else {
      this.router.navigate([url])
    }
  }

  public redirectToUrlWithReload(url: string) {
    if (this.isEmbedded) {
      this.redirect.dispatch(Redirect.Action.APP, `/app${url}`)
    } else {
      this.router.navigate([url]).then(() => {
        window.location.reload();
      })
    }
  }

  public openNewWindow(url: string) {
    if (this.isEmbedded) {
      this.redirect.dispatch(Redirect.Action.REMOTE,
        {
          url: `${this.shopifyAppUrl}${url}`,
          newContext: true
        }
      )
    } else {
      window.open(`https://${environment.hostname}${url}`, '_blank')
    }
  }

  public enableFullScreen() {
    if (this.isEmbedded) {
      this.fullScreenWasEnabled = true
      this._fullscreen.dispatch(Fullscreen.Action.ENTER)
      this.fullScreen$.next(true)
    }
  }

  public disableFullScreen() {
    if (this.isEmbedded) {
      this._fullscreen.dispatch(Fullscreen.Action.EXIT)
      this.fullScreen$.next(false)
    }
  }

  public getShopifyAuthUrl(method: 'login' | 'register'): string {
    const hostname = window.location.hostname
    if (hostname === 'app-develop.one.store') {
      return `https://shopify-develop.one.store/app/${method}/shopify`
    }
    if (hostname === 'app-staging.one.store') {
      return `https://shopify-staging.one.store/app/${method}/shopify`
    }
    if (hostname === 'app.one.store') {
      return `https://shopify.one.store/app/${method}/shopify`
    }
    if (hostname === 'app-develop.prooffactor.com') {
      return `https://shopify-develop.prooffactor.com/app/${method}/shopify`
    }
    if (hostname === 'app-staging.prooffactor.com') {
      return `https://shopify-staging.prooffactor.com/app/${method}/shopify`
    }
    if (hostname === 'app.prooffactor.com') {
      return `https://shopify.prooffactor.com/app/${method}/shopify`
    }
    return null
  }

  public allowedStandaloneRedirect(
    planName: string, accountId: string, email: string, url: string
  ): boolean {
    // Check if current plan is on the list
    const isAllowedPlan = this.allowedStandalonePlans.includes(planName)
    // Check if it's one of our emails
    const internalEmail = email?.includes('@one.store') || email?.includes('@morpheusone.com')
    // Check if it's one of allowed accounts
    const isAllowedAccount = this.allowedStandaloneAccountsIds.includes(accountId)
    // Check if standalone app wasn't already opened
    // Skip login and dashboard redirects to avoid popup being blocked, since they happen automatically and not by user action
    const ignoredRedirect = url.includes('/login') ||
                            url.includes('/dashboard') ||
                            url.includes('/onboarding/signup/new/welcome') ||
                            url.includes('/onboarding/signup/returning/welcome') ||
                            url.includes('/apps?onboarding=true') ||
                            url.includes('/marketing_emails') // we handle open method in unlayer-dnd.guard.ts already
    return (isAllowedPlan || internalEmail || isAllowedAccount) && !ignoredRedirect
  }

  navigateToThemeEditor(domainName: string) {
    const path = `/themes/current/editor`

    if (this.isEmbedded) {
      this.redirect.dispatch(Redirect.Action.ADMIN_PATH, {
        path: path,
        newContext: true,
      })
      return
    }
    window.open(`https://admin.shopify.com/store/${domainName}${path}`, '_blank')
  }
}
